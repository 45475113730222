import { useContext, useEffect, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LanguageContext } from '../../library/contextproviders/LanguageProvider';
import { Account } from '../../library/Interfaces/IAccount';
import { ISettingsJson } from '../../library/Interfaces/ISettings';
import { Role } from '../../library/Role';
import { accountService } from '../../services/account.service';
import { settingsService } from '../../services/settings.service';

export function AdminNavigationBar() {
    const [user, setUser] = useState<Account | null>();
    const [] = useContext(LanguageContext);
    const { t } = useTranslation();
    const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
    const [settings, setSettings] = useState<ISettingsJson>(null);

    useEffect(() => {
        settingsService.getForDomain().then(settings => {
            // Parse Json
            if (settings.settingsJson) {
                const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
                setSettings(settingsJson);
            }
            else {
                // TODO: Initiate
            }
            setSettingsRetrieved(true);
        });

        const subscription = accountService.user.subscribe(x => setUser(x));
        return () => subscription.unsubscribe(); // see https://stackoverflow.com/questions/71149561/react-unsubscribe-from-rxjs-subscription-in-useeffect
    }, []);

    // Only show nav when logged in
    if (!user) return null;

    return (
        <Navbar className="navbar navbar-expand navbar-dark bg-dark">
            <Container className="navbar-nav">
                <NavLink to="/admin/pages" className="nav-item nav-link">{t('pages')}</NavLink>

                {settingsRetrieved && settings.portfolio.isOnline &&
                    <NavLink to={`/admin/${settings.portfolio.slug}`} className="nav-item nav-link">{settings.portfolio.displayName}</NavLink>
                }

                <NavLink to="/admin/forms" className="nav-item nav-link">{t('forms')}</NavLink>
                <NavLink to="/admin/settings" className="nav-item nav-link">Settings</NavLink>
                {user.role === Role.SuperAdmin &&
                    <NavLink to="/admin/account" className="nav-item nav-link">Accounts</NavLink>
                }
                <Navbar.Toggle />
                <NavLink to="/" target="_blank" className="nav-item nav-link">Visit the Cabin</NavLink>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>Welcome to the cockpit <strong>{user.firstName} {user.lastName}</strong></Navbar.Text>
                    <NavLink to="/admin/profile" className="nav-item nav-link">Profile</NavLink>
                    <a onClick={accountService.logout} className="nav-item nav-link">Logout</a>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}