import { Suspense, useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { IPage } from '../../library/Interfaces/IPage';
import { ISettingsJson } from '../../library/Interfaces/ISettings';
import { pageService } from '../../services/page.service';
import { settingsService } from '../../services/settings.service';
import Footer from '../Footer';
import { Navigation } from '../navigation/Navigation';

export function PortfolioList() {
    const [pages, setPages] = useState<IPage[]>([]);
    const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
    const [settings, setSettings] = useState<ISettingsJson>(null);

    useEffect(() => {
        pageService.getAllPortfolioPagesForDomain().then(pages => {
            setPages(pages);
        });

        // TODO: use react ref to only load once? Also use redux to cache domain settings for browser session? 
        settingsService.getForDomain().then(settings => {
            // Parse Json
            if (settings.settingsJson) {
                const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
                setSettings(settingsJson);
            }
            else {
                // TODO: Initiate
            }
            setSettingsRetrieved(true);
        });
    }, []);

    return (
        <div id="page-container" className="Main">
            {settingsRetrieved &&
                <>
                    <div id="content-wrap">
                        <Helmet><title>{settings.general.siteTitle}</title><meta name="description" content={settings.general.siteDescription} /></Helmet>
                        <Navigation />
                        <Container>
                            {pages &&
                                // Display a nice bootstrap styled list of alle the portfolio pages here, by showing the title of each page
                                pages.map((page, index) => {
                                    return (
                                        <Card style={{ width: '38rem' }} className="m-2 bg-light bg-gradient" key={`quickLinkCard-${index}`}>
                                            <Card.Body>
                                                <Card.Title>{page.title}</Card.Title>
                                                {/* <Card.Subtitle className="mb-2 text-muted">{quickLink.pricingLine}</Card.Subtitle> */}
                                                {/* <Card.Text>
                                        {quickLink.text &&
                                            ReactHtmlParser(quickLink.text as string)
                                        }
                                        </Card.Text> */}
                                                <div>
                                                    <a className="mt-1 btn btn-dark" href={`${settings.portfolio.slug}/${page.slug}`}>Lees meer</a>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })
                            }
                        </Container>            </div>
                    <Suspense fallback={null}>
                        <Footer />
                    </Suspense>
                </>
            }
        </div>
    );
}