import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidv4 } from 'uuid';
import { ContentBlockComponent } from '../../../library/Components';
import { IContentBlock } from '../../../library/Interfaces/IContentBlock';
import { ContentBlockAdder } from './ContentBlockAdder';

export function ContentBlocksRenderer(props) {
    // Hooks
    const [contentBlockComponents, setContentBlockComponents] = useState<ReactElement[]>([]);
    const isInitialMount = useRef(true);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [panelContents, setPanelContents] = useState<JSX.Element>();
    const renderPanelContents = (contents: JSX.Element) => {
        setIsPanelOpen(true);
        setPanelContents(contents);
    };

    useEffect(() => {
        const updateContentBlock = (contentBlock: IContentBlock) => {
            const contentBlocksObject = JSON.parse(props.contentBlocksJSON);
            const cbIndex = contentBlocksObject.contentBlocks.findIndex(x => x.id === contentBlock.id);
            contentBlocksObject.contentBlocks[cbIndex] = contentBlock;
            props.updateContentBlocksJSON(JSON.stringify(contentBlocksObject));
        };

        const deleteContentBlock = (contentBlock: IContentBlock) => {
            const contentBlocksObject = JSON.parse(props.contentBlocksJSON);
            const cbIndex = contentBlocksObject.contentBlocks.findIndex(x => x.id === contentBlock.id);
            contentBlocksObject.contentBlocks.splice(cbIndex, 1);
            props.updateContentBlocksJSON(JSON.stringify(contentBlocksObject));
        };

        const addContentBlock = (contentBlock: IContentBlock) => {
            if (props.contentBlocksJSON) {
                const contentBlocksObject = JSON.parse(props.contentBlocksJSON);

                // Get index of contentblock with specified sequence
                const cbSequenceIndex = contentBlocksObject.contentBlocks.findIndex(x => x.sequence === (contentBlock.sequence).toString());

                // If not last contentblock: increment each sequence of all contentblocks, so there's space at target sequence
                contentBlocksObject.contentBlocks.forEach((cb: IContentBlock) => {
                    const lastContentBlockSequenceIndex = (Number(contentBlocksObject.contentBlocks.length) + 1);
                    if (cb.sequence >= contentBlock.sequence && cb.sequence !== lastContentBlockSequenceIndex) {
                        cb.sequence++;
                    }
                });

                // Add contentblock at specified index, don't remove any
                contentBlocksObject.contentBlocks.splice(cbSequenceIndex, 0, contentBlock);

                props.updateContentBlocksJSON(JSON.stringify(contentBlocksObject));
            }
            else {
                const contentBlocksObject = { contentBlocks: [contentBlock] };
                props.updateContentBlocksJSON(JSON.stringify(contentBlocksObject));
            }
        };

        const closeAndAdd = (contentBlock: IContentBlock) => {
            addContentBlock(contentBlock);
            setIsPanelOpen(false);
        }

        const closeAndDelete = (contentBlock: IContentBlock) => {
            deleteContentBlock(contentBlock);
            setIsPanelOpen(false);
        }

        const closeAndUpdate = (contentBlock: IContentBlock) => {
            updateContentBlock(contentBlock);
            setIsPanelOpen(false);
        }

        if (isInitialMount.current) {
            updateContentBlocks();
            isInitialMount.current = false;
        } else {
            // useEffect code here to be run on update
            updateContentBlocks();
        }

        function updateContentBlocks() {
            if (props.contentBlocksJSON) {
                const contentBlocksObject = JSON.parse(props.contentBlocksJSON);
                if (contentBlocksObject.contentBlocks.length > 0) {
                    contentBlocksObject.contentBlocks.sort((a, b) => a.sequence - b.sequence);
                    const blockComponents: ReactElement[] = [];

                    // Loop through blockcomponents and add one "Adder block" before each component and also one as the last one
                    // in order to let the user add new contentblocks
                    // The adder block should first display a modal or dropdown list with all available content blocks to choose
                    // After that it should render the add panel for that particular content block and also remember the index to which 
                    // the new cb will need to be added
                    for (let index = 0; index < contentBlocksObject.contentBlocks.length; index++) {
                        const contentBlock = contentBlocksObject.contentBlocks[index];
                        const blockComponent = ContentBlockComponent({
                            key: uuidv4(),
                            contentBlock: contentBlock,
                            showEditMode: props.showEditMode,
                            setIsPanelOpen: setIsPanelOpen,
                            renderPanelContents: renderPanelContents,
                            closeAndUpdate: closeAndUpdate,
                            closeAndDelete: closeAndDelete
                        });

                        if (props.showEditMode) {
                            const adder = React.createElement(ContentBlockAdder,
                                {
                                    key: uuidv4(), renderPanelContents: renderPanelContents, closeAndAdd: closeAndAdd, closeAndUpdate: closeAndUpdate, setIsPanelOpen: setIsPanelOpen,
                                    sequence: Number(contentBlock.sequence)
                                });
                            blockComponents.push(adder);
                        }
                        blockComponents.push(blockComponent);
                    }

                    // Last one
                    if (props.showEditMode) {
                        const adder = React.createElement(ContentBlockAdder,
                            {
                                key: uuidv4(), renderPanelContents: renderPanelContents, closeAndAdd: closeAndAdd, closeAndUpdate: closeAndUpdate, setIsPanelOpen: setIsPanelOpen,
                                sequence: (Number(contentBlocksObject.contentBlocks.length) + 1)
                            });
                        blockComponents.push(adder);
                    }

                    setContentBlockComponents(blockComponents);
                }
            }
            else {
                if (props.showEditMode) {
                    const blockComponents: ReactElement[] = [];
                    const adder = React.createElement(ContentBlockAdder,
                        {
                            key: uuidv4(), renderPanelContents: renderPanelContents, closeAndAdd: closeAndAdd, closeAndUpdate: closeAndUpdate, setIsPanelOpen: setIsPanelOpen,
                            sequence: Number(0)
                        });
                    blockComponents.push(adder);

                    setContentBlockComponents(blockComponents);
                }
                else {
                    setContentBlockComponents([React.createElement('div', null, 'no data')]);
                }
            }
        }
        //}, contentBlocks);
    }, [props.contentBlocksJSON, isPanelOpen, props.showEditMode, props]);

    // useIsMounted();

    return (
        <>
            <div id="ContentBlocks">{contentBlockComponents}</div>

            {props.showEditMode &&
                <div id="ContentBlockEditPanel">
                    <Modal className='right fade' size='lg' show={isPanelOpen} onHide={() => setIsPanelOpen(false)}>
                        <Modal.Header closeButton><Modal.Title>Content Block edit panel</Modal.Title></Modal.Header>
                        <Modal.Body>{panelContents}</Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}