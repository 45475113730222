import { useEffect, useState } from "react";
import { Container, Image, Nav } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { ISettingsJson } from "../library/Interfaces/ISettings";
import { pageService } from "../services/page.service";
import { settingsService } from "../services/settings.service";

export default function Main(props) {
    const [footerPages, setFooterPages] = useState<any[]>([]);
    const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
    const [settings, setSettings] = useState<ISettingsJson>(null);
    const [logoDarkUrl, setLogoDarkUrl] = useState<string>('');
    const [logoLightUrl, setLogoLightUrl] = useState<string>('');

    useEffect(() => {
        settingsService.getForDomain().then(settings => {
            // Parse Json
            if (settings.settingsJson) {
                const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
                setSettings(settingsJson);

                setLogoDarkUrl(settingsJson.branding.logoDarkUrl);
                setLogoLightUrl(settingsJson.branding.logoLightUrl);
            }
            else {
                // TODO: Initiate
            }
            setSettingsRetrieved(true);
        });

        pageService.getAllFooterPagesForDomain().then(pages => {
            setFooterPages(pages);
        });
    }, []);

    return (
        <>
            {settingsRetrieved &&
                <Container>
                    <Container id="footer" className="mt-2" fluid>
                        <Nav className="flex-column">
                            {footerPages.map((footerPage, index) => (
                                <LinkContainer to={`/${footerPage.slug}`} key={footerPage.slug} >
                                    <Nav.Link>{footerPage.title}</Nav.Link>
                                </LinkContainer>))
                            }
                        </Nav>
                        <div>
                            <Image src={logoDarkUrl} rounded />
                        </div>
                        <div>
                            <div>
                                <IconContext.Provider value={{ size: "28" }}>
                                    <div className="social-links">
                                        {settings.footer.instagramUrl &&
                                            <a href={settings.footer.instagramUrl} target="_blank" rel="noreferrer"><FaInstagram /></a>
                                        }
                                        {settings.footer.facebookUrl &&
                                            <a href={settings.footer.facebookUrl} target="_blank" rel="noreferrer"><FaFacebook /></a>
                                        }
                                        {settings.footer.linkedInUrl &&
                                            <a href={settings.footer.linkedInUrl} target="_blank" rel="noreferrer"><FaLinkedin /></a>
                                        }
                                    </div>
                                </IconContext.Provider>
                            </div>
                        </div>
                    </Container>
                    <Container id="footerSubRow" className="mt-2" fluid>
                        <div className="copyright">Copyright</div>

                        <div className="links ml-1">
                            <a href={settings.footer.termsAndConditionsUrl} className="ml-1" target="_blank" rel="noreferrer">Algemene voorwaarden</a>
                            <a href={settings.footer.privacyStatementUrl} className="ml-1" target="_blank" rel="noreferrer">Privacyverklaring</a>
                        </div>
                    </Container>
                </Container>
            }
        </>
    );
}