import { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { ISettingsJson } from '../library/Interfaces/ISettings';
import { pageService } from '../services/page.service';
import { settingsService } from '../services/settings.service';
import { ContentBlocksRenderer } from './contentblocks/support/ContentBlocksRenderer';
import { Navigation } from './navigation/Navigation';

export default function Main(props) {
  const [contentBlocksJSON, setContentBlocksJSON] = useState<string>('');
  const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
  const [settings, setSettings] = useState<ISettingsJson>(null);
  const { slug } = useParams();

  const Footer = lazy(() => import('./Footer'));

  useEffect(() => {
    if (slug) {
      pageService.getBySlugForDomain(slug).then(page => {
        setContentBlocksJSON(page.contentBlocks);
      });
    } else {
      pageService.getHomepageForDomain().then(page => {
        setContentBlocksJSON(page.contentBlocks);
      });
    }

    // TODO: use react ref to only load once? Also use redux to cache domain settings for browser session? 
    settingsService.getForDomain().then(settings => {
      // Parse Json
      if (settings.settingsJson) {
        const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
        setSettings(settingsJson);
      }
      else {
        // TODO: Initiate
      }
      setSettingsRetrieved(true);
    });

  }, [slug]);

  return (
    <div id="page-container" className="Main">
      {settingsRetrieved &&
        <>
          <div id="content-wrap">
            <Helmet><title>{settings.general.siteTitle}</title><meta name="description" content={settings.general.siteDescription} /></Helmet>
            <Navigation />
            {contentBlocksJSON ? <ContentBlocksRenderer contentBlocksJSON={contentBlocksJSON} showEditMode={false} /> : ""}
          </div>
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </>
      }
    </div>
  );
}