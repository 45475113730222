import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { IContentBlock } from '../../../library/Interfaces/IContentBlock';

export function ContentBlocksReorderer(props) {
    const [contentBlockComponents, setContentBlockComponents] = useState<ReactElement[]>([]);
    const isInitialMount = useRef(true);
    const dragItem = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        if (isInitialMount.current) {
            buildContentBlocks();
            isInitialMount.current = false;
        } else {
            // useEffect code here to be run on update
            buildContentBlocks();
        }

        function buildContentBlocks() {
            if (props.contentBlocksJSON) {
                const contentBlocksObject = JSON.parse(props.contentBlocksJSON);
                if (contentBlocksObject.contentBlocks.length > 0) {
                    // Sort the content blocks by sequence
                    contentBlocksObject.contentBlocks.sort((a, b) => a.sequence - b.sequence);
                    const blockComponents: ReactElement[] = [];

                    for (let index = 0; index < contentBlocksObject.contentBlocks.length; index++) {
                        const contentBlock = contentBlocksObject.contentBlocks[index];
                        const blockComponent = <Container className="draggableItem" key={`${contentBlock.id}|${contentBlock.sequence}`}
                            draggable id={`${contentBlock.id}|${contentBlock.sequence}`}
                            onDragStart={(e) => dragStart(e)}
                            onDragEnter={(e) => dragEnter(e)}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnd={drop}>
                            <Row className='p-1 justify-content-md-center'>
                                <Col lg="1" md="12"><MdOutlineDragIndicator /></Col>
                                <Col lg="9" md="12"><p key={`editPricingTitle-${index}`}>{contentBlock.properties.name}</p></Col>
                            </Row>
                        </Container>

                        blockComponents.push(blockComponent);
                    }
                    setContentBlockComponents(blockComponents);
                }
            }
            else {
                setContentBlockComponents([React.createElement('div', null, 'no data')]);
            }
        }
    }, [props.contentBlocksJSON, props]);

    const dragStart = (e) => {
        dragItem.current = e.target;
    }

    const dragEnter = (e) => {
        if (dragOverItem.current) {
            let dragOverItemElement = dragOverItem.current as any;
            if (dragOverItemElement && dragOverItemElement.id !== e.currentTarget.id) {
                dragOverItemElement.classList.remove('over');
            }
        }
        dragOverItem.current = e.currentTarget;
        e.currentTarget.classList.add('over');
    }

    const drop = () => {
        console.log('drop current' + dragItem.current);
        console.log('on target' + dragOverItem.current);

        if (dragItem.current && dragOverItem.current) {
            // Get the contentblock id and sequence of dragged item and target item
            let dragItemElement = dragItem.current as any;
            let dragItemId = dragItemElement.id as string;
            let dragItemInfo = dragItemId.split('|');
            const dragItemCbId = dragItemInfo[0];
            const dragItemCbSequence = dragItemInfo[1];

            let dragOverItemElement = dragOverItem.current as any;
            dragOverItemElement.classList.remove('over');
            let dragOverItemId = dragOverItemElement.id as string;
            let dragOverItemInfo = dragOverItemId.split('|');
            const dragOverItemCbId = dragOverItemInfo[0];
            const dragOverItemCbSequence = dragOverItemInfo[1];

            // Get the dragitem cb (index by  sequence)
            const contentBlocksObject = JSON.parse(props.contentBlocksJSON);
            const dragItemIndex = contentBlocksObject.contentBlocks.findIndex(x => x.id === dragItemCbId);
            const dragOverItemIndex = contentBlocksObject.contentBlocks.findIndex(x => x.id === dragOverItemCbId);

            const newDraggedContentBlock: IContentBlock = { ...contentBlocksObject.contentBlocks[dragItemIndex] };
            newDraggedContentBlock.sequence = parseInt(dragOverItemCbSequence);

            // Remove the dragged cb from original position
            contentBlocksObject.contentBlocks.splice(dragItemIndex, 1);

            // Add dragged item at index (second parameter is number of items to be removed)
            contentBlocksObject.contentBlocks.splice(dragOverItemIndex, 0, newDraggedContentBlock);

            // Reset
            dragItem.current = null;
            dragOverItem.current = null;

            // Use a newly constructed object in order to fire useEffect (otherwise root object did not change)
            props.updateContentBlocksJSON(JSON.stringify(contentBlocksObject));
        }
    }

    return (
        // Show a container with a list ordered by sequence with a handlebar and name for each content block 
        <div id="ContentBlocks">{contentBlockComponents}</div>
    )
}