import { Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from 'yup';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import { ISettingsJson } from '../../library/Interfaces/ISettings';
import { formService } from '../../services/form.service';
import { settingsService } from '../../services/settings.service';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockContact(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  // const [contactValue, setContactValue] = useState(props.contentBlock.properties.text);
  const [isCaptchaTokenValid, setIsCaptchaTokenValid] = useState<boolean>(false);
  const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
  const [settings, setSettings] = useState<ISettingsJson>(null);
  const [showContactFormSendText, setShowContactFormSendText] = useState<boolean>(false);

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    settingsService.getForDomain().then(settings => {
      // Parse Json
      if (settings.settingsJson) {
        const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
        setSettings(settingsJson);
      }
      else {
        // TODO: Initiate
      }
      setSettingsRetrieved(true);
    });
  }, []);


  const editPanelContents =
    <Container className="mt-2">
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  function handleFormikSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    sendContactForm(fields, setSubmitting);
    setShowContactFormSendText(true);
  }

  function sendContactForm(fields, setSubmitting) {
    const stringifiedMessage = JSON.stringify(fields.message);
    const payload = { formJson: `{"name": "${fields.name}", "email": "${fields.email}", "phone": "${fields.phone}", "message": ${stringifiedMessage} }` };
    formService.send(payload)
      .then(() => {
        console.log('Update successful');
        //navigate('..');
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        console.error(error, null);
      });
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    message: yup.string().required()
  });

  const formik = useFormik({
    initialValues: { name: '', email: '', phone: '', message: '' },
    validationSchema: validationSchema,
    onSubmit: handleFormikSubmit
  });

  function onChangeReCAPTCHA(token) {
    // console.log("Captcha value:", token);

    formService.validateCaptcha(token)
      .then(() => {
        // setToken(token);
        // setTokenStatus(TokenStatus.Valid);
        // Enable send button
        setIsCaptchaTokenValid(true);
      })
      .catch(() => {
        // setTokenStatus(TokenStatus.Invalid);
        // Show error
        setIsCaptchaTokenValid(false);
      });
  }


  return (
    <Container className="mt-2 cbContactForm">
      {settingsRetrieved &&
        <>
          {props.showEditMode && <ContentBlockEditToolbar contentBlock={contentBlock} editPanelContents={editPanelContents} {...props} />}

          {props.showAddMode && editPanelContents}

          {!props.showAddMode && formik &&
            <FormikProvider value={formik}>
              <Container className="mt-2 contactForm p-3" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
                {!showContactFormSendText &&
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xl="6" lg="6" md="12">
                        <Field name="name" render={({ field, formProps }) => (
                          <FormGroup controlId="name" className='mt-2'>
                            <FormLabel>Naam *</FormLabel>
                            <FormControl type={'text'} value={field.value} onChange={field.onChange} onBlur={field.onBlur}
                              placeholder="Please fill in your name here"
                              isInvalid={!!formik.errors.name}
                              isValid={formik.touched.name && !formik.errors.name} />
                            <FormControl.Feedback>Looks good!</FormControl.Feedback>
                            <FormControl.Feedback type="invalid">
                              {formik.errors.name as string}
                            </FormControl.Feedback>
                          </FormGroup>
                        )}
                        />
                        <Field name="email" render={({ field, formProps }) => (
                          <FormGroup controlId="email" className='mt-2'>
                            <FormLabel>E-mail adres *</FormLabel>
                            <FormControl type={'text'} value={field.value} onChange={field.onChange} onBlur={field.onBlur}
                              placeholder="Please fill in your e-mail address here"
                              isInvalid={!!formik.errors.email}
                              isValid={formik.touched.email && !formik.errors.email} />
                            <FormControl.Feedback>Looks good!</FormControl.Feedback>
                            <FormControl.Feedback type="invalid">
                              {formik.errors.email as string}
                            </FormControl.Feedback>
                          </FormGroup>
                        )}
                        />
                        <Field name="phone" render={({ field, formProps }) => (
                          <FormGroup controlId="phone" className='mt-2'>
                            <FormLabel>Telefoonnummer</FormLabel>
                            <FormControl type={'text'} value={field.value} onChange={field.onChange} />
                          </FormGroup>
                        )}
                        />
                        <FormGroup controlId="email" className='mt-3'>
                          {settings.forms.siteKeyRecaptcha &&
                            <ReCAPTCHA sitekey={settings.forms.siteKeyRecaptcha} onChange={onChangeReCAPTCHA} size="normal" />
                          }
                        </FormGroup>
                      </Col>
                      <Col xl="6" lg="6" md="12">
                        <Field name="message" render={({ field, formProps }) => (
                          <FormGroup controlId="message" className='mt-2'>
                            <FormLabel>Bericht *</FormLabel>
                            <FormControl as="textarea" rows={2} className="h-200" style={{ height: '200px' }} defaultValue={field.value} onChange={field.onChange} onBlur={field.onBlur}
                              placeholder="Please fill in your message here"
                              isInvalid={!!formik.errors.message}
                              isValid={formik.touched.message && !formik.errors.message} />
                            <FormControl.Feedback>Looks good!</FormControl.Feedback>
                            <FormControl.Feedback type="invalid">
                              {formik.errors.message as string}
                            </FormControl.Feedback>
                          </FormGroup>
                        )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 4, offset: 8 }} className="text-end">
                        <Form.Group className="mt-3" controlId="formButtonGroup">
                          <Button type="submit" variant="primary" className='btn-dark' disabled={formik.isSubmitting || !isCaptchaTokenValid}>
                            {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Send
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                }
                {showContactFormSendText &&
                  <Row>
                    <Col xl="6" lg="6" md="12">{settings.forms.contactFormSendText}</Col>
                  </Row>
                }
              </Container>
            </FormikProvider>
          }
        </>
      }
    </Container>
  )
}