import { useEffect, useState } from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IMenuItem } from '../../library/Interfaces/IMenuItem';
import { ISettingsJson } from '../../library/Interfaces/ISettings';
import { pageService } from '../../services/page.service';
import { settingsService } from '../../services/settings.service';

interface IDropDownToggleState {
  title: string,
  slug: string,
  show: boolean
}

export function Navigation(props) {
  const [menuItems, setMenuItems] = useState<IMenuItem[] | null>([]);
  const [dropDownToggleState, setDropDownToggleState] = useState<IDropDownToggleState[] | null>([]);
  const navigate = useNavigate();
  const initialDropDownToggleData: IDropDownToggleState[] = [];
  const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
  const [settings, setSettings] = useState<ISettingsJson>(null);

  const populateInitialDropDownToggleData = (menuItems: IMenuItem[]) => {
    menuItems.forEach(menuItem => {
      const dropDownToggleState: IDropDownToggleState = {
        title: menuItem.title,
        slug: menuItem.slug,
        show: false
      };
      initialDropDownToggleData.push(dropDownToggleState);

      if (menuItem.children) {
        populateInitialDropDownToggleData(menuItem.children);
      }
    });
  };

  const handleOnMouseLeaveOrEnter = (event: React.MouseEvent<HTMLElement>, slug: string, show: boolean): void => {
    // Prevent race condition, use prevstate!
    setDropDownToggleState(prevState => {
      const updatedState = prevState.map(dropDownToggleState => {
        if (dropDownToggleState.slug === slug) {
          return { ...dropDownToggleState, show: show };
        }
        return dropDownToggleState;
      });
      return [...updatedState];
    });
  };

  const showDropDown = (slug: string) => {
    if (dropDownToggleState && dropDownToggleState.length > 0) {
      const item = dropDownToggleState.find(x => x.slug === slug);
      return item ? item.show : false;
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    pageService.getMenuItemsForDomain().then(response => {
      setMenuItems(response.menuItems);
      populateInitialDropDownToggleData(response.menuItems);
      setDropDownToggleState(initialDropDownToggleData);
    });

    settingsService.getForDomain().then(settings => {
      // Parse Json
      if (settings.settingsJson) {
        const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
        setSettings(settingsJson);
      }
      else {
        // TODO: Initiate
      }
      setSettingsRetrieved(true);
    });
  }, []);

  return (
    <Container className='navigationBar'>
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="navbarSupportedContent">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarSupportedContent">
          {menuItems.length > 0 &&
            <Nav className="mr-auto">
              {menuItems.map(menuItem => {
                if (menuItem.children == null || menuItem.children.length === 0) {
                  return (
                    <Nav.Item key={`"navItem${menuItem.slug}`}>
                      <LinkContainer to={`/${menuItem.slug}`} key={menuItem.slug} >
                        <Nav.Link>{menuItem.title}</Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                  )
                }
                else {
                  // We've got a submenu on depth 1
                  return (
                    <Nav.Item key={`"navItem"${menuItem.slug}`}>
                      <NavDropdown title={menuItem.title} id={`nav-dropdown-d1-${menuItem.slug}`}
                        onClick={(e) => { navigate('/' + menuItem.slug); e.stopPropagation(); }}
                        onMouseLeave={(event) => handleOnMouseLeaveOrEnter(event, menuItem.slug, false)}
                        onMouseEnter={(event) => handleOnMouseLeaveOrEnter(event, menuItem.slug, true)}
                        show={showDropDown(menuItem.slug)}>
                        {menuItem.children != null && menuItem.children.length > 0 && menuItem.children.map(subMenuItemDepth1 => {
                          if (subMenuItemDepth1.children == null || subMenuItemDepth1.children.length === 0) {
                            return (
                              <NavDropdown.Item key={`navItem${subMenuItemDepth1.slug}`}
                                onClick={(e) => { navigate('/' + subMenuItemDepth1.slug); e.stopPropagation(); }}>
                                {subMenuItemDepth1.title}
                              </NavDropdown.Item>
                            )
                          }
                          else {
                            // We've got a submenu on depth 2: not supported any deeper
                            return (
                              <Nav.Item key={`navItem${subMenuItemDepth1.slug}`}>
                                <NavDropdown title={subMenuItemDepth1.title} id={`nav-dropdown-d2-${subMenuItemDepth1.slug}`}
                                  onClick={(e) => { navigate('/' + subMenuItemDepth1.slug); e.stopPropagation(); }}
                                  onMouseLeave={(event) => handleOnMouseLeaveOrEnter(event, subMenuItemDepth1.slug, false)}
                                  onMouseEnter={(event) => handleOnMouseLeaveOrEnter(event, subMenuItemDepth1.slug, true)}
                                  show={showDropDown(subMenuItemDepth1.slug)}>
                                  {subMenuItemDepth1.children != null && subMenuItemDepth1.children.length > 0 && subMenuItemDepth1.children.map(subMenuItemDepth2 => {
                                    return (
                                      <NavDropdown.Item key={`navItem${subMenuItemDepth2.slug}`}
                                        onClick={(e) => { navigate('/' + subMenuItemDepth2.slug); e.stopPropagation(); }}>
                                        {subMenuItemDepth2.title}
                                      </NavDropdown.Item>
                                    )
                                  })}
                                </NavDropdown>
                              </Nav.Item>
                            )
                          }
                        })}
                      </NavDropdown>
                    </Nav.Item>
                  )
                }
              }
              )}

              {settingsRetrieved && settings.portfolio.isOnline &&
                <Nav.Item key={`"navItem${settings.portfolio.slug}`}>
                  <LinkContainer to={`/${settings.portfolio.slug}`} key={settings.portfolio.slug} >
                    <Nav.Link>{settings.portfolio.displayName}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              }
            </Nav>
          }
        </Navbar.Collapse>
      </Navbar>
    </Container >
  )
}