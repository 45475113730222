import { fetchWrapper } from '../library/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_API_URL}/settings`;

export const settingsService = {
    getForDomain,
    create,
    update
};

function getForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetForDomain`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
        .then(settings => {
            return settings;
        });
}