import { Outlet, Route, Routes } from 'react-router-dom';
import { SettingsEdit } from './SettingsEdit';

export function SettingsRouter() {
    return (
        <>
            <Routes>
                <Route index element={<SettingsEdit />} />
            </Routes>
            <Outlet />
        </>
    );
}