import { fetchWrapper } from '../library/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_API_URL}/languages`;

export const languageService = {
    getAll
};

function getAll() {
    return fetchWrapper.get(`${baseUrl}/GetAll`);
}