import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import YouTube from "react-youtube";
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockYoutube(props) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [videoCodeValue, setVideoCodeValue] = useState(props.contentBlock.properties.videoCode);

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    contentBlock.properties.name = nameValue;
    contentBlock.properties.title = titleValue;
    contentBlock.properties.videoCode = videoCodeValue;
    setContentBlock(contentBlock);
  }, [contentBlock, nameValue, titleValue, videoCodeValue]);

  const editPanelContents =
    <Container className="mt-2">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
        <Form.Control type="text" name="title" defaultValue={titleValue}
          onChange={(event) => setTitleValue(event.target.value)}
          placeholder="Please fill in your title here" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInputVideoCode" label="Video code" className="mb-2">
        <Form.Control type="text" name="videoCode" defaultValue={videoCodeValue}
          onChange={(event) => setVideoCodeValue(event.target.value)}
          placeholder="Please fill in your cideo code here" />
      </FloatingLabel>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  return (
    <Container className="mt-2 cbYoutube">
      {props.showEditMode &&
        <ContentBlockEditToolbar contentBlock={contentBlock} renderPanelContents={props.renderPanelContents} closeAndDelete={props.closeAndDelete}
          editPanelContents={editPanelContents} />}

      {props.showAddMode && editPanelContents}

      {!props.showAddMode &&
        <Container className="mt-2" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          <Row className="h1 margintop-small">{props.contentBlock.properties.title && props.contentBlock.properties.title as string}</Row>
          <Row>
            <Col lg="2" md="12"></Col>
            <Col lg="10" md="12">
              {props.contentBlock.properties.videoCode &&
                <YouTube
                  videoId={props.contentBlock.properties.videoCode}
                // opts={opts}
                />
              }
            </Col>
          </Row>
        </Container>
      }
    </Container>
  )
}