import { useEffect, useRef, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { IContentBlockProvider } from '../../../library/Interfaces/IContentBlockProvider';
import { ContentBlockContactProvider } from '../ContentBlockContactProvider';
import { ContentBlockHeaderProvider } from '../ContentBlockHeaderProvider';
import { ContentBlockImageProvider } from '../ContentBlockImageProvider';
import { ContentBlockImageTextProvider } from '../ContentBlockImageTextProvider';
import { ContentBlockPhotoGalleryProvider } from '../ContentBlockPhotoGalleryProvider';
import { ContentBlockPricingListProvider } from '../ContentBlockPricingListProvider';
import { ContentBlockQuickLinksProvider } from '../ContentBlockQuickLinksProvider';
import { ContentBlockQuoteProvider } from '../ContentBlockQuoteProvider';
import { ContentBlockReviewsProvider } from '../ContentBlockReviewsProvider';
import { ContentBlockTextProvider } from '../ContentBlockTextProvider';
import { ContentBlockYoutubeProvider } from '../ContentBlockYoutubeProvider';

export function ContentBlockPicker(props: any) {
    const isInitialMount = useRef(true);
    const providers = new Array<IContentBlockProvider>();
    const [contentBlockPickerItems, setContentBlockPickerItems] = useState<JSX.Element[]>([]);

    useEffect(() => {
        if (isInitialMount.current) {
            // Use provider / decorator pattern to make generic call to all contentblock components, so they can handle it themselves!
            providers.push(new ContentBlockHeaderProvider(props));
            providers.push(new ContentBlockImageProvider(props));
            providers.push(new ContentBlockTextProvider(props));
            providers.push(new ContentBlockImageTextProvider(props));
            providers.push(new ContentBlockYoutubeProvider(props));
            providers.push(new ContentBlockPricingListProvider(props));
            providers.push(new ContentBlockQuickLinksProvider(props));
            providers.push(new ContentBlockReviewsProvider(props));
            providers.push(new ContentBlockQuoteProvider(props));
            providers.push(new ContentBlockContactProvider(props));
            providers.push(new ContentBlockPhotoGalleryProvider(props));

            const pickerItems: JSX.Element[] = [];
            for (const provider of providers) {
                if (provider.contentBlockPickerItem) {
                    pickerItems.push(provider.contentBlockPickerItem);
                }
            }

            setContentBlockPickerItems(pickerItems);
            isInitialMount.current = false;
        } else {
            // Your useEffect code here to be run on update
        }
    }, []);

    // const getPanelContents = (renderPanelContents, closeAndAdd, closeAndUpdate, setIsPanelOpen, sequence: number): JSX.Element => {
    return (<>
        <Container className='mt-2'>
            <Stack gap={3}>
                {contentBlockPickerItems}
            </Stack>
        </Container>
    </>
    );
}