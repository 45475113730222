import { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Col, Container, FloatingLabel, Form, Modal, Row, Stack } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { GrAdd, GrEdit } from 'react-icons/gr';
import { MdOutlineDragIndicator } from "react-icons/md";
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import { IReview } from '../../library/Interfaces/IContentBlockProps';
import { generateImage } from '../../library/utils/imageGenerator';
import getQuillModules from '../../library/utils/quill-utils';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';

export function ContentBlockReviews(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [selectedReview, setSelectedReview] = useState<{ index: number, review: IReview }>({ index: -1, review: {} });
  const [renderReviews, setRenderReviews] = useState<boolean>(true);
  const [renderReview, setRenderReview] = useState<boolean>(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const quillModules = getQuillModules();
  const dragItem = useRef();
  const dragOverItem = useRef();

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  const changePanelOpen = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  }

  const handleConfirm = (): void => {
    if (props.showAddMode) {
      props.closeAndAdd(contentBlock);
    } else {
      props.closeAndUpdate(contentBlock);
      setIsPanelOpen(false);
    }
  };

  const updateReview = (): void => {
    contentBlock.properties.reviews[selectedReview.index] = selectedReview.review;
    setContentBlock(contentBlock);
    showReviewsComponent();
  }

  const handleEditReviewClick = (index): void => {
    // Set contents of selected review in state for edit panel to modify
    // After ok click: modify review for contentblock in memory   
    const review = contentBlock.properties.reviews[index];
    setSelectedReview({ index: index, review: { ...review } }); // Create a shallow copy
  };

  const handleAddReviewClick = (index): void => {
    setSelectedReview({ index: index, review: {} });
  };

  const handleDeleteReviewClick = (index): void => {
    contentBlock.properties.reviews.splice(index, 1)
    setContentBlock(contentBlock);
    setSelectedReview({ index: -1, review: {} });
  };

  const showReviewsComponent = () => {
    setRenderReview(false);
    setRenderReviews(true);
  }

  useEffect(() => {
    contentBlock.properties.title = titleValue;
    contentBlock.properties.name = nameValue;
    setContentBlock(contentBlock);
  }, [nameValue, titleValue, contentBlock]);

  useEffect(() => {
    const showReviewComponent = () => {
      setRenderReviews(false);
      setRenderReview(true);
    }

    if (selectedReview.index !== -1) {
      // In case of a new review: set the value on the content block
      // contentBlock.properties.reviews[selectedReview.index] = selectedReview.review;
      // setContentBlock(contentBlock);
      showReviewComponent();
    }
  }, [contentBlock, selectedReview]);

  const reviewForm =
    <Container className="mt-2 cbReviewsForm">
      <Form>
        <Form.Group className="mb-3" controlId="reviewTitle">
          <FloatingLabel controlId="floatingInputReviewTitle" label="Review title" className="mb-2">
            <Form.Control type="input" defaultValue={selectedReview.review.title}
              onChange={(event) => selectedReview.review.title = event.target.value} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="reviewText">
          <Form.Label>Review text</Form.Label>
          <ReactQuill theme="snow" value={selectedReview.review.text} modules={quillModules}
            onChange={(newValue) => selectedReview.review.text = newValue} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="author">
          <FloatingLabel controlId="floatingInputAuthor" label="Author" className="mb-2">
            <Form.Control type="input" defaultValue={selectedReview.review.author}
              onChange={(event) => selectedReview.review.author = event.target.value} />
          </FloatingLabel>
        </Form.Group>
        <Stack direction="horizontal" gap={2} className="mt-2 justify-content-end">
          <Button className='mt-2' variant="primary" onClick={updateReview}>Confirm</Button>
          <Button className='mt-2' variant="light" onClick={() => showReviewsComponent()}>Close</Button>
        </Stack>
      </Form>
    </Container>

  const dragStart = (e) => {
    console.log('dragStart on currentTarget:' + e.currentTarget.id);
    dragItem.current = e.target;
    setSelectedReview({ index: -1, review: {} });
    //e.target.className = 'mb-3 border border-primary dragCursor';
  }

  const dragEnter = (e) => {
    console.log('dragEnter on currentTarget:' + e.currentTarget.id);

    if (dragOverItem.current) {
      let dragOverItemElement = dragOverItem.current as any;
      console.log('dragLeave dragOverItemElement.id:' + dragOverItemElement.id);
      console.log('dragLeave e.currentTarget.id:' + e.currentTarget.id);
      if (dragOverItemElement && dragOverItemElement.id !== e.currentTarget.id) {
        console.log('remove');
        dragOverItemElement.classList.remove('over');
      }
    }

    dragOverItem.current = e.currentTarget;
    e.currentTarget.classList.add('over');
  }

  const drop = () => {
    console.log('drop current' + dragItem.current);
    console.log('drop target' + dragOverItem.current);

    if (dragItem.current && dragOverItem.current) {
      let dragItemElement = dragItem.current as any;
      //dragItemElement.className = 'mb-3';
      let dragItemId = dragItemElement.id as string;
      let dragItemIndex: number = dragItemId.split('review-')[1] as unknown as number;
      let dragOverItemElement = dragOverItem.current as any;
      dragOverItemElement.classList.remove('over');
      let dragOverItemId = dragOverItemElement.id as string;
      let dragOverItemIndex: number = dragOverItemId.split('review-')[1] as unknown as number;
      const newContentBlock = { ...contentBlock };
      const dragItemContent = newContentBlock.properties.reviews[dragItemIndex];
      newContentBlock.properties.reviews.splice(dragItemIndex, 1);
      newContentBlock.properties.reviews.splice(dragOverItemIndex, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setContentBlock(newContentBlock); // Use a newly constructed object in order to fire useEffect (otherwise root object did not change)
    }
  }

  const reviewsForm =
    <Container fluid className="mt-2 cbReviewsDetailForm">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
        <Form.Control type="text" name="reviewsTitle" defaultValue={titleValue}
          onChange={(event) => setTitleValue(event.target.value)}
          placeholder="Please fill in your title here" />
      </FloatingLabel>
      <Form.Group className="mb-3" controlId="reviews">
        <Form.Label>Reviews</Form.Label>
        <Form.Group className="mb-3 border border-secondary-subtle p-3">
          {contentBlock.properties.reviews.map((review: IReview, index) => (
            <Form.Group className="draggableItem" key={`review-${index}`}
              draggable id={`review-${index}`}
              onDragStart={(e) => dragStart(e)}
              onDragEnter={(e) => dragEnter(e)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={drop}>
              <Row className='p-1 justify-content-md-center'>
                <Col lg="1" md="12"><MdOutlineDragIndicator /></Col>
                <Col lg="9" md="12"><p key={`editReviewTitle-${index}`}>{review.title}</p></Col>
                <Col lg="1" md="12"><Button variant="light" title="Edit" key={`editIcon${index}`} onClick={() => { handleEditReviewClick(index); }}><GrEdit /></Button></Col>
                <Col lg="1" md="12"><Button variant="light" title="Delete" key={`deleteIcon${index}`} onClick={() => { handleDeleteReviewClick(index); }}><RiDeleteBin6Line /></Button></Col>
              </Row>
            </Form.Group>
          ))}
          <hr></hr>
          <Form.Label>
            <span title="Add">
              <Button variant="light" disabled={contentBlock.properties.reviews.length > 5} onClick={() => { handleAddReviewClick(contentBlock.properties.reviews.length); }}><GrAdd /></Button>
            </span>
          </Form.Label>
        </Form.Group>
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} handleConfirm={handleConfirm} handleSetIsPanelOpen={changePanelOpen} contentBlock={contentBlock} {...props} />
    </Container >

  // When in AddMode, the component is rendered in panel through adder component
  // When in EditMode, the component is rendered in panel through it's own button click

  return (
    <Container className="paddingtop-large cbReviews">

      {props.showEditMode &&
        <Stack direction="horizontal" gap={1} className="mt-2">
          <span title="Edit">
            <Button variant="light" onClick={() => { setIsPanelOpen(true); }}><GrEdit /></Button>
          </span>
          <span title="Delete">
            <Button variant="light" onClick={() => props.closeAndDelete(props.contentBlock)}><RiDeleteBin6Line /></Button>
          </span>
        </Stack>}

      {props.showEditMode &&
        <div id="ContentBlockEditPanel">
          <Modal className='right fade' size='lg' show={isPanelOpen} onHide={() => setIsPanelOpen(false)}>
            <Modal.Header closeButton><Modal.Title>Content Block edit panel</Modal.Title></Modal.Header>
            <Modal.Body>
              {renderReviews && reviewsForm}
              {renderReview && reviewForm}
            </Modal.Body>
          </Modal>
        </div>
      }

      {props.showAddMode && renderReviews && reviewsForm}
      {props.showAddMode && renderReview && reviewForm}

      {!props.showAddMode &&
        <div>
          <Carousel data-bs-theme="dark">
            {contentBlock.properties.reviews.map((review, index) => {
              const imageUrl = generateImage(' ', 800, 300, '#eee', '#000000');
              return (
                <Carousel.Item>
                  <img className="d-block w-100 carousel-image" src={imageUrl} alt={review.title} />
                  <Carousel.Caption>
                    <h4>{review.title}</h4>
                    <p>{review.text && ReactHtmlParser(review.text as string)}</p>
                    <p>{review.author}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            }
            )}
          </Carousel>
        </div>
      }
    </Container>
  )
}