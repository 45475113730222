import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockQuote(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [quoteValue, setQuoteValue] = useState(props.contentBlock.properties.text);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    setContentBlock((prevStateContentBlock) => {
      const updatedContentBlock = { ...prevStateContentBlock };
      updatedContentBlock.properties.text = quoteValue;
      updatedContentBlock.properties.name = nameValue;
      return updatedContentBlock;
    });
  }, [nameValue, quoteValue]);


  const editPanelContents =
    <Container className="mt-2 cbQuoteEdit">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      <Form.Group controlId="quoteGroup">
        <FloatingLabel controlId="floatingInputQuote" label="Quote" className="mb-2">
          <Form.Control as="textarea" rows={3} name="quote" defaultValue={quoteValue} style={{ height: '100px' }}
            onChange={(event) => setQuoteValue(event.target.value)}
            placeholder="Please fill in your quote here" />
        </FloatingLabel>
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  return (
    <Container className="mt-2 cbQuote">
      {props.showEditMode && <ContentBlockEditToolbar contentBlock={contentBlock} editPanelContents={editPanelContents} {...props} />}

      {props.showAddMode && editPanelContents}

      {!props.showAddMode &&
        <Container className="mt-2" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          <Row>
            <Col>
              <blockquote className='text-handwritten'>{quoteValue}</blockquote>
            </Col>
          </Row>
        </Container>
      }
    </Container>
  )
}