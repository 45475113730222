import { useEffect, useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { IContentBlockComponentProps } from '../../../library/Interfaces/IContentBlockComponentProps';
import { ContentBlockPicker } from './ContentBlockPicker';

export function ContentBlockEditFooter(props: IContentBlockComponentProps) {
    const [isOnline, setIsOnline] = useState<boolean>(props.contentBlock.isOnline);

    const handleClose = (): void => {
        if (props.showAddMode) {
            // Show add picker again
            props.renderPanelContents(<ContentBlockPicker {...props} />)
        } else {
            props.handleSetIsPanelOpen != null ? props.handleSetIsPanelOpen(false) : props.setIsPanelOpen(false);
        }
    };

    const handleConfirm = (): void => {
        if (props.showAddMode) {
            props.closeAndAdd(props.contentBlock);
        } else {
            props.closeAndUpdate(props.contentBlock);
        }
    };

    useEffect(() => {
        if (props.contentBlock.isOnline !== isOnline) {
            const modifiedContentBlock = props.contentBlock;
            modifiedContentBlock.isOnline = isOnline;
            props.updateContentBlock(modifiedContentBlock);
        }
    }, [isOnline, props]);

    return (
        <>
            <Form.Group controlId="isOnlineGroup" className="mt-2">
                <Form.Switch label="Online" checked={isOnline} onChange={() => setIsOnline(!isOnline)} />
            </Form.Group>
            <Stack direction="horizontal" gap={2} className="mt-2 justify-content-end">
                <Button className='mt-2' variant="primary" onClick={props.handleConfirm != null ? props.handleConfirm : handleConfirm}>Confirm</Button>
                <Button className='mt-2' variant="light" onClick={props.handleClose != null ? props.handleClose : handleClose}>
                    {props.showAddMode ? 'Back' : 'Close'}
                </Button>
            </Stack>
        </>
    )
}