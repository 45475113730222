import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row, Stack } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { GrAdd, GrEdit } from 'react-icons/gr';
import { MdOutlineDragIndicator } from "react-icons/md";
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import { IPricing } from '../../library/Interfaces/IContentBlockProps';
import getQuillModules from '../../library/utils/quill-utils';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';

export function ContentBlockPricingList(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [selectedPricing, setSelectedPricing] = useState<{ index: number, pricing: IPricing }>({ index: -1, pricing: {} });
  const [renderPricingList, setRenderPricingList] = useState<boolean>(true);
  const [renderPricing, setRenderPricing] = useState<boolean>(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const quillModules = getQuillModules();
  const dragItem = useRef();
  const dragOverItem = useRef();

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  const changePanelOpen = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  }

  const handleConfirm = (): void => {
    if (props.showAddMode) {
      props.closeAndAdd(contentBlock);
    } else {
      props.closeAndUpdate(contentBlock);
      setIsPanelOpen(false);
    }
  };

  const updatePricing = (): void => {
    contentBlock.properties.pricings[selectedPricing.index] = selectedPricing.pricing;
    setContentBlock(contentBlock);
    showPricingListComponent();
  }

  const handleEditPricingClick = (index): void => {
    // Set contents of selected pricing in state for edit panel to modify
    // After ok click: modify pricing for contentblock in memory   
    const pricing = contentBlock.properties.pricings[index];
    setSelectedPricing({ index: index, pricing: { ...pricing } }); // Create a shallow copy
  };

  const handleAddPricingClick = (index): void => {
    // contentBlock.properties.pricings.splice(index, 0, {});
    // setContentBlock(contentBlock);
    setSelectedPricing({ index: index, pricing: {} });
  };

  const handleDeletePricingClick = (index): void => {
    contentBlock.properties.pricings.splice(index, 1)
    setContentBlock(contentBlock);
    setSelectedPricing({ index: -1, pricing: {} });
  };

  const showPricingListComponent = () => {
    setRenderPricing(false);
    setRenderPricingList(true);
  }

  useEffect(() => {
    contentBlock.properties.title = titleValue;
    contentBlock.properties.name = nameValue;
    setContentBlock(contentBlock);
  }, [nameValue, titleValue, contentBlock]);

  useEffect(() => {
    const showPricingComponent = () => {
      setRenderPricingList(false);
      setRenderPricing(true);
    }

    if (selectedPricing.index !== -1) {
      // In case of a new pricing: set the value on the content block
      // contentBlock.properties.pricings[selectedPricing.index] = selectedPricing.pricing;
      // setContentBlock(contentBlock);
      showPricingComponent();
    }
  }, [contentBlock, selectedPricing]);

  const pricingForm =
    <Container className="mt-2 cbPricingListForm">
      <Form>
        <Form.Group className="mb-3" controlId="pricingTitle">
          <FloatingLabel controlId="floatingInputPricingTitle" label="Pricing title" className="mb-2">
            <Form.Control type="input" defaultValue={selectedPricing.pricing.title}
              onChange={(event) => selectedPricing.pricing.title = event.target.value} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="pricingLine">
          <FloatingLabel controlId="floatingInputPricingLine" label="Pricing line" className="mb-2">
            <Form.Control type="input" defaultValue={selectedPricing.pricing.pricingLine}
              onChange={(event) => selectedPricing.pricing.pricingLine = event.target.value} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="pricingText">
          <Form.Label>Pricing text</Form.Label>
          <ReactQuill theme="snow" value={selectedPricing.pricing.text} modules={quillModules}
            onChange={(newValue) => selectedPricing.pricing.text = newValue} />
        </Form.Group>
        <Stack direction="horizontal" gap={2} className="mt-2 justify-content-end">
          <Button className='mt-2' variant="primary" onClick={updatePricing}>Confirm</Button>
          <Button className='mt-2' variant="light" onClick={() => showPricingListComponent()}>Close</Button>
        </Stack>
      </Form>
    </Container>

  const dragStart = (e) => {
    console.log('dragStart on currentTarget:' + e.currentTarget.id);
    dragItem.current = e.target;
    setSelectedPricing({ index: -1, pricing: {} });
    //e.target.className = 'mb-3 border border-primary dragCursor';
  }

  const dragEnter = (e) => {
    console.log('dragEnter on currentTarget:' + e.currentTarget.id);

    if (dragOverItem.current) {
      let dragOverItemElement = dragOverItem.current as any;
      console.log('dragLeave dragOverItemElement.id:' + dragOverItemElement.id);
      console.log('dragLeave e.currentTarget.id:' + e.currentTarget.id);
      if (dragOverItemElement && dragOverItemElement.id !== e.currentTarget.id) {
        console.log('remove');
        dragOverItemElement.classList.remove('over');
      }
    }

    dragOverItem.current = e.currentTarget;
    e.currentTarget.classList.add('over');
  }

  const drop = () => {
    console.log('drop current' + dragItem.current);
    console.log('drop target' + dragOverItem.current);

    if (dragItem.current && dragOverItem.current) {
      let dragItemElement = dragItem.current as any;
      //dragItemElement.className = 'mb-3';
      let dragItemId = dragItemElement.id as string;
      let dragItemIndex: number = dragItemId.split('pricing-')[1] as unknown as number;
      let dragOverItemElement = dragOverItem.current as any;
      dragOverItemElement.classList.remove('over');
      let dragOverItemId = dragOverItemElement.id as string;
      let dragOverItemIndex: number = dragOverItemId.split('pricing-')[1] as unknown as number;
      const newContentBlock = { ...contentBlock };
      const dragItemContent = newContentBlock.properties.pricings[dragItemIndex];
      newContentBlock.properties.pricings.splice(dragItemIndex, 1);
      newContentBlock.properties.pricings.splice(dragOverItemIndex, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setContentBlock(newContentBlock); // Use a newly constructed object in order to fire useEffect (otherwise root object did not change)
    }
  }

  const pricingListForm =
    <Container fluid className="mt-2 cbPricingListDetailForm">

      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
        <Form.Control type="text" name="pricingListTitle" defaultValue={titleValue}
          onChange={(event) => setTitleValue(event.target.value)}
          placeholder="Please fill in your title here" />
      </FloatingLabel>
      <Form.Group className="mb-3" controlId="pricings">
        <Form.Label>Tarieven</Form.Label>
        <Form.Group className="mb-3 border border-secondary-subtle p-3">
          {contentBlock.properties.pricings.map((pricing: IPricing, index) => (
            <Form.Group className="draggableItem" key={`pricing-${index}`}
              draggable id={`pricing-${index}`}
              onDragStart={(e) => dragStart(e)}
              onDragEnter={(e) => dragEnter(e)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={drop}>
              <Row className='p-1 justify-content-md-center'>
                <Col lg="1" md="12"><MdOutlineDragIndicator /></Col>
                <Col lg="9" md="12"><p key={`editPricingTitle-${index}`}>{pricing.title}</p></Col>
                <Col lg="1" md="12"><Button variant="light" title="Edit" key={`editIcon${index}`} onClick={() => { handleEditPricingClick(index); }}><GrEdit /></Button></Col>
                <Col lg="1" md="12"><Button variant="light" title="Delete" key={`deleteIcon${index}`} onClick={() => { handleDeletePricingClick(index); }}><RiDeleteBin6Line /></Button></Col>
              </Row>
            </Form.Group>
          ))}
          <hr></hr>
          <Form.Label>
            <span title="Add">
              <Button variant="light" disabled={contentBlock.properties.pricings.length > 5} onClick={() => { handleAddPricingClick(contentBlock.properties.pricings.length); }}><GrAdd /></Button>
            </span>
          </Form.Label>
        </Form.Group>
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} handleConfirm={handleConfirm} handleSetIsPanelOpen={changePanelOpen} contentBlock={contentBlock} {...props} />
    </Container >

  // When in AddMode, the component is rendered in panel through adder component
  // When in EditMode, the component is rendered in panel through it's own button click

  return (
    <Container className="paddingtop-large cbPricingList">

      {props.showEditMode &&
        <Stack direction="horizontal" gap={1} className="mt-2">
          <span title="Edit">
            <Button variant="light" onClick={() => { setIsPanelOpen(true); }}><GrEdit /></Button>
          </span>
          <span title="Delete">
            <Button variant="light" onClick={() => props.closeAndDelete(props.contentBlock)}><RiDeleteBin6Line /></Button>
          </span>
        </Stack>}

      {props.showEditMode &&
        <div id="ContentBlockEditPanel">
          <Modal className='right fade' size='lg' show={isPanelOpen} onHide={() => setIsPanelOpen(false)}>
            <Modal.Header closeButton><Modal.Title>Content Block edit panel</Modal.Title></Modal.Header>
            <Modal.Body>
              {renderPricingList && pricingListForm}
              {renderPricing && pricingForm}
            </Modal.Body>
          </Modal>
        </div>
      }

      {props.showAddMode && renderPricingList && pricingListForm}
      {props.showAddMode && renderPricing && pricingForm}

      {!props.showAddMode &&
        <div id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          <p className="h1 margintop-small">{titleValue && ReactHtmlParser(titleValue as string)}</p>
          <div className="stripe-under"></div>
          <div className="pricingCards d-flex flex-wrap">
            {contentBlock.properties.pricings.map((pricing, index) => (
              <Card style={{ width: '38rem' }} className="m-2 bg-light bg-gradient" key={`pricingCard-${index}`}>
                <Card.Body>
                  <Card.Title>{pricing.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{pricing.pricingLine}</Card.Subtitle>
                  <Card.Text>
                    {pricing.text &&
                      ReactHtmlParser(pricing.text as string)
                    }
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      }
    </Container>
  )
}