import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import { LanguageProvider } from './library/contextproviders/LanguageProvider';
import './polyfills';
import reportWebVitals from './reportWebVitals';
import { accountService } from './services/account.service';

// Attempt silent token refresh before startup
accountService.refreshToken().finally(startApp);

function startApp() {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  root.render(
    <React.StrictMode>
      <Router>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </Router>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();