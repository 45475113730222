import { Suspense, useEffect, useState } from 'react';
// import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

import { Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import { StylesheetAppender } from './components/StylesheetAppender';
import { AccountForgotPassword } from './components/admin/account/AccountForgotPassword';
import { AccountLogin } from './components/admin/account/AccountLogin';
import { AccountResetPassword } from './components/admin/account/AccountResetPassword';
import { AccountRouter } from './components/admin/account/AccountRouter';
import { AccountVerifyEmail } from './components/admin/account/AccountVerifyEmail';
import { FormsRouter } from './components/admin/forms/FormsRouter';
import { PageRouter } from './components/admin/pages/PageRouter';
import { PortfolioRouter } from './components/admin/portfolio/PortfolioRouter';
import { ProfileRouter } from './components/admin/profile/ProfileRouter';
import { SettingsRouter } from './components/admin/settings/SettingsRouter';
import { AdminNavigationBar } from './components/navigation/AdminNavigationBar';
import { PrivateRoutes } from './components/navigation/PrivateRoute';
import { PortfolioList } from './components/portfolio/PortfolioList';
import './i18n';
import { ISettings, ISettingsJson } from './library/Interfaces/ISettings';
import { Role } from './library/Role';
import { accountService } from './services/account.service';
import { settingsService } from './services/settings.service';

export default function App() {

  //STEP 1:
  //create components using React.lazy
  // const MorningDewTheme = React.lazy(() => import('./themes/MorningDew'));

  // const [themeName, setThemeName] = useState<string>('');
  const [settings, setSettings] = useState<ISettings>(null);
  const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
  const [settingsJson, setSettingsJson] = useState<ISettingsJson>(null);
  const [user, setUser] = useState<{} | null>({});

  useEffect(() => {
    // TODO: use react ref to only load once? Also use redux to cache domain settings for browser session? 
    settingsService.getForDomain().then(settings => {
      // setThemeName(settings.theme.fileNameWithoutExtension);
      setSettings(settings);
      if (settings.settingsJson) {
        const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
        setSettingsJson(settingsJson);
      }
      else {
        // TODO: Initiate
      }
      setSettingsRetrieved(true);
    });

    const subscription = accountService.user.subscribe(x => setUser(x));
    return () => subscription.unsubscribe(); // see https://stackoverflow.com/questions/71149561/react-unsubscribe-from-rxjs-subscription-in-useeffect
  }, []);

  return (
    <div className="App">
      <Suspense fallback={null}>
        {settings ? <StylesheetAppender settings={settings} /> : ""}

        {settingsRetrieved &&
          <Routes>
            <Route path="admin/*" element={<PrivateRoutes roles={[Role.Admin, Role.SuperAdmin]}><AdminNavigationBar /></PrivateRoutes>}>
              <Route path="pages/*" element={<PageRouter />} />
              <Route path="forms/*" element={<FormsRouter />} />
              <Route path="account/*" element={<AccountRouter />} />
              <Route path="profile/*" element={<ProfileRouter />} />
              <Route path="settings/*" element={<SettingsRouter />} />
              <Route path={`${settingsJson.portfolio.slug}/*`} element={<PortfolioRouter />} />
            </Route>
            <Route path="account/login" element={<AccountLogin />} />
            <Route path="account/verify-email" element={<AccountVerifyEmail />} />
            <Route path="account/forgot-password" element={<AccountForgotPassword />} />
            <Route path="account/reset-password" element={<AccountResetPassword />} />
            <Route path="/" element={<Main />} />
            {settingsJson.portfolio.isOnline &&
              <>
                <Route path={`${settingsJson.portfolio.slug}`} element={<PortfolioList />} />
                <Route path={`${settingsJson.portfolio.slug}/:slug`} element={<Main />} />
              </>
            }
            <Route path="/:slug" element={<Main />} />
          </Routes>
        }
      </Suspense>
    </div>
  );
}