import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Role } from '../../library/Role';
import { accountService } from '../../services/account.service';

interface PrivateRoutesProps {
    roles: string[];
    children?: JSX.Element;
}

export function PrivateRoutes(props: PrivateRoutesProps = { roles: [Role.Admin, Role.SuperAdmin] }) {
    let location = useLocation();
    const user: any = accountService.userValue;
    if (!user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/account/login" state={{ from: location }} />
    }

    // check if route is restricted by role
    if (props.roles && props.roles.indexOf(user.role) === -1) {
        // role not authorized so redirect to home page
        return <Navigate to={{ pathname: '/' }} />
    }

    // authorized so return component
    return <div className="container">
        {props.children}
        <Outlet />
    </div>
}