import { Button, Container } from 'react-bootstrap';
import { GrAdd } from 'react-icons/gr';
import { ContentBlockPicker } from './ContentBlockPicker';

export function ContentBlockAdder(props: any) {

  return (
    <Container className="paddingtop-large">
      <hr className="hr" />

      <span title="Add">
        <Button variant="light" onClick={() => props.renderPanelContents(<ContentBlockPicker {...props} />)}>
          <GrAdd /></Button>
      </span>

      <hr className="hr" />
    </Container>
  )
}