// src/utils/canvasUtils.ts
export function generateImage(text: string, width: number, height: number, backgroundColor: string, textColor: string): string {
    const scaleFactor = 2; // Increase the scale factor for higher resolution
    const canvas = document.createElement('canvas');
    canvas.width = width * scaleFactor;
    canvas.height = height * scaleFactor;
    const ctx = canvas.getContext('2d');

    if (ctx) {
        // Set the background color
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Set the text color and font
        ctx.fillStyle = textColor;
        ctx.font = `bold ${20 * scaleFactor}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Draw the text
        ctx.fillText(text, canvas.width / 2, canvas.height / 3);

        // Scale down the canvas
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = width;
        scaledCanvas.height = height;
        const scaledCtx = scaledCanvas.getContext('2d');
        if (scaledCtx) {
            scaledCtx.drawImage(canvas, 0, 0, width, height);
        }

        // Return the data URL of the scaled image
        return scaledCanvas.toDataURL('image/png');
    }

    return '';
}