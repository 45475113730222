import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import { ImageAddZone } from '../ImageAddZone';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockImage(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [imagePath, setImagePath] = useState(props.contentBlock.properties.imagePath);
  const [showCallToActionChecked, setShowCallToActionChecked] = useState<boolean>(props.contentBlock.properties.showCallToAction);
  const [ctaTextValue, setCtaTextValue] = useState(props.contentBlock.properties.callToActionText);
  const [ctaUrlValue, setCtaUrlValue] = useState(props.contentBlock.properties.callToActionUrl);
  const [showTitleChecked, setShowTitleChecked] = useState<boolean>(props.contentBlock.properties.showTitle);

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    contentBlock.properties.imagePath = imagePath;
    contentBlock.properties.name = nameValue;
    contentBlock.properties.title = titleValue;
    contentBlock.properties.showCallToAction = showCallToActionChecked;
    contentBlock.properties.callToActionText = ctaTextValue;
    contentBlock.properties.callToActionUrl = ctaUrlValue;
    contentBlock.properties.showTitle = showTitleChecked;
    setContentBlock(contentBlock);
  }, [contentBlock, nameValue, titleValue, imagePath, showCallToActionChecked, showTitleChecked, ctaTextValue, ctaUrlValue]);

  const editPanelContents =
    <Container className="mt-2">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
        <Form.Control type="text" name="title" defaultValue={titleValue}
          onChange={(event) => setTitleValue(event.target.value)}
          placeholder="Please fill in your title here" />
      </FloatingLabel>
      <Form.Group controlId="imageGroup">
        <ImageAddZone imagePath={props.contentBlock.properties.imagePath} setImagePath={setImagePath} />
      </Form.Group>
      {showCallToActionChecked &&
        <Form.Group controlId="ctaGroup">
          <hr />
          <FloatingLabel controlId="floatingInputCtaText" label="Call to action text" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaTextValue}
              onChange={(event) => setCtaTextValue(event.target.value)}
              placeholder="Please fill in your call to action text here" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInputCtaUrl" label="Call to action url" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaUrlValue}
              onChange={(event) => setCtaUrlValue(event.target.value)}
              placeholder="Please fill in your call to action link here" />
          </FloatingLabel>
        </Form.Group>
      }
      <Form.Group controlId="showTitleGroup" className="mt-2">
        <Form.Switch label="Show title" defaultChecked={showTitleChecked} onChange={() => setShowTitleChecked(oldValue => !oldValue)} />
      </Form.Group>
      <Form.Group controlId="showCallToActionGroup" className="mt-2">
        <Form.Switch label="Show call to action" defaultChecked={showCallToActionChecked} onChange={() => setShowCallToActionChecked(oldValue => !oldValue)} />
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  return (
    <Container className="mt-2 cbImage">
      {props.showEditMode &&
        <ContentBlockEditToolbar contentBlock={contentBlock} renderPanelContents={props.renderPanelContents} closeAndDelete={props.closeAndDelete}
          editPanelContents={editPanelContents} />}

      {props.showAddMode && editPanelContents}

      {!props.showAddMode && props.contentBlock.properties.imagePath ?
        <Container className="mt-2" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          {showTitleChecked && titleValue &&
            <Row className="h1 margintop-small">{titleValue}</Row>
          }
          <Row>
            <Col lg="2" md="12"></Col>
            <Col lg="10" md="12">
              <div>
                <Image src={props.contentBlock.properties.imagePath} fluid />
              </div>
              {(showCallToActionChecked && showCallToActionChecked === true) &&
                <div>
                  <a className="mt-1 btn btn-dark" href={ctaUrlValue}>{ctaTextValue}</a>
                </div>
              }
            </Col>
          </Row>
        </Container>
        : null
      }
    </Container>
  )
}