import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import getQuillModules from '../../library/utils/quill-utils';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockText(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [textValue, setTextValue] = useState(props.contentBlock.properties.text);
  const [textColumnTwoValue, setTextColumnTwoValue] = useState(props.contentBlock.properties.textColumnTwo);
  const [showCallToActionChecked, setShowCallToActionChecked] = useState<boolean>(props.contentBlock.properties.showCallToAction);
  const [ctaTextValue, setCtaTextValue] = useState(props.contentBlock.properties.callToActionText);
  const [ctaUrlValue, setCtaUrlValue] = useState(props.contentBlock.properties.callToActionUrl);
  const [showTitleChecked, setShowTitleChecked] = useState<boolean>(props.contentBlock.properties.showTitle);
  const [isTwoColumnsChecked, setIsTwoColumnsChecked] = useState<boolean>(props.contentBlock.properties.isTwoColumns);

  const modules = getQuillModules();

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    setContentBlock((prevStateContentBlock) => {
      const updatedContentBlock = { ...prevStateContentBlock };
      updatedContentBlock.properties.name = nameValue;
      updatedContentBlock.properties.title = titleValue;
      updatedContentBlock.properties.text = textValue;
      updatedContentBlock.properties.textColumnTwo = textColumnTwoValue;
      updatedContentBlock.properties.showCallToAction = showCallToActionChecked;
      updatedContentBlock.properties.callToActionText = ctaTextValue;
      updatedContentBlock.properties.callToActionUrl = ctaUrlValue;
      updatedContentBlock.properties.showTitle = showTitleChecked;
      updatedContentBlock.properties.isTwoColumns = isTwoColumnsChecked;

      return updatedContentBlock;
    });
  }, [nameValue, titleValue, textValue, textColumnTwoValue, showCallToActionChecked, showTitleChecked, isTwoColumnsChecked, ctaTextValue, ctaUrlValue]);

  const editPanelContents =
    <Container className="mt-2 cbTextEdit">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      {showTitleChecked &&
        <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
          <Form.Control type="text" name="title" defaultValue={titleValue}
            onChange={(event) => setTitleValue(event.target.value)}
            placeholder="Please fill in your title here" />
        </FloatingLabel>
      }
      <Form.Group controlId="textGroup" className="mt-2">
        <Form.Label>Text</Form.Label>
        <ReactQuill theme="snow" value={textValue} modules={modules} onChange={setTextValue} />
      </Form.Group>
      {isTwoColumnsChecked &&
        <Form.Group controlId="textGroupSecond" className="mt-2">
          <Form.Label>Text Column Two</Form.Label>
          <ReactQuill theme="snow" value={textColumnTwoValue} modules={modules} onChange={setTextColumnTwoValue} />
        </Form.Group>
      }
      {showCallToActionChecked &&
        <Form.Group controlId="ctaGroup">
          <hr />
          <FloatingLabel controlId="floatingInputCtaText" label="Call to action text" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaTextValue}
              onChange={(event) => setCtaTextValue(event.target.value)}
              placeholder="Please fill in your call to action text here" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInputCtaUrl" label="Call to action url" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaUrlValue}
              onChange={(event) => setCtaUrlValue(event.target.value)}
              placeholder="Please fill in your call to action link here" />
          </FloatingLabel>
        </Form.Group>
      }
      <Form.Group controlId="switchGroup" className="mt-2">
        <Form.Switch className="mt-2" label="Show title" defaultChecked={showTitleChecked} onChange={() => setShowTitleChecked(oldValue => !oldValue)} />
        <Form.Switch className="mt-2" label="Is two columns" defaultChecked={isTwoColumnsChecked} onChange={() => setIsTwoColumnsChecked(oldValue => !oldValue)} />
        <Form.Switch className="mt-2" label="Show call to action" defaultChecked={showCallToActionChecked} onChange={() => setShowCallToActionChecked(oldValue => !oldValue)} />
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  return (
    <Container className="mt-2 cbText">
      {props.showEditMode && <ContentBlockEditToolbar contentBlock={contentBlock} editPanelContents={editPanelContents} {...props} />}

      {props.showAddMode && editPanelContents}

      {!props.showAddMode &&
        <Container className="mt-2" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          {showTitleChecked && titleValue &&
            <Row className="h1 margintop-small">{titleValue}</Row>
          }
          {!isTwoColumnsChecked &&
            <Row>
              <Col>
                <div>{textValue && ReactHtmlParser(textValue as string)}</div>
                {(showCallToActionChecked && showCallToActionChecked === true) &&
                  <div>
                    <a className="mt-1 mb-3 btn btn-dark" href={ctaUrlValue}>{ctaTextValue}</a>
                  </div>
                }
              </Col>
            </Row>
          }
          {isTwoColumnsChecked &&
            <Row>
              <Col lg="6" md="12">
                <div>{textValue && ReactHtmlParser(textValue as string)}</div>
              </Col>
              <Col lg="6" md="12">
                <div>{textColumnTwoValue && ReactHtmlParser(textColumnTwoValue as string)}</div>
                {(showCallToActionChecked && showCallToActionChecked === true) &&
                  <div>
                    <a className="mt-1 mb-3 btn btn-dark" href={ctaUrlValue}>{ctaTextValue}</a>
                  </div>
                }
              </Col>
            </Row>
          }
        </Container>
      }
    </Container>
  )
}