import { Outlet, Route, Routes } from 'react-router-dom';
import { PageAddEdit } from './PageAddEdit';
import { PageList } from './PageList';

export function PageRouter() {
    return (
        <>
            <Routes>
                <Route index element={<PageList />} />
                <Route path={`add`} element={<PageAddEdit pageTypeId={1} />} />
                <Route path={`edit/:id`} element={<PageAddEdit pageTypeId={1} />} />
            </Routes>
            <Outlet />
        </>
    );
}