import { Outlet, Route, Routes } from 'react-router-dom';
import { AccountAddEdit } from './AccountAddEdit';
import { AccountList } from './AccountList';
import { AccountRegister } from './AccountRegister';

export function AccountRouter() {
    return (
        <>
            <Routes>
                <Route index element={<AccountList />} />
                <Route path={`add`} element={<AccountAddEdit />} />
                <Route path={`edit/:id`} element={<AccountAddEdit />} />
                <Route path={`register`} element={<AccountRegister />} />
            </Routes>
            <Outlet />
        </>
    );
}