import React from "react";
import { ContentBlockContact } from "../components/contentblocks/ContentBlockContact";
import { ContentBlockHeader } from "../components/contentblocks/ContentBlockHeader";
import { ContentBlockImage } from "../components/contentblocks/ContentBlockImage";
import { ContentBlockImageText } from "../components/contentblocks/ContentBlockImageText";
import { ContentBlockPhotoGallery } from "../components/contentblocks/ContentBlockPhotoGallery";
import { ContentBlockPricingList } from "../components/contentblocks/ContentBlockPricingList";
import { ContentBlockQuickLinks } from "../components/contentblocks/ContentBlockQuickLinks";
import { ContentBlockQuote } from "../components/contentblocks/ContentBlockQuote";
import { ContentBlockReviews } from "../components/contentblocks/ContentBlockReviews";
import { ContentBlockText } from "../components/contentblocks/ContentBlockText";
import { ContentBlockYoutube } from "../components/contentblocks/ContentBlockYoutube";
import { IContentBlockComponentProps } from "./Interfaces/IContentBlockComponentProps";

export const ContentBlockComponents = {
  contentblockheader: ContentBlockHeader,
  contentblocktext: ContentBlockText,
  contentblockimage: ContentBlockImage,
  contentblockimagetext: ContentBlockImageText,
  contentblockyoutube: ContentBlockYoutube,
  contentblockpricinglist: ContentBlockPricingList,
  contentblockquicklinks: ContentBlockQuickLinks,
  contentblockreviews: ContentBlockReviews,
  contentblockquote: ContentBlockQuote,
  contentblockcontact: ContentBlockContact,
  contentblockphotogallery: ContentBlockPhotoGallery,
};

export function ContentBlockComponent(props: IContentBlockComponentProps) {
  // Component does exist
  if (typeof ContentBlockComponents[props.contentBlock.component.toLocaleLowerCase()] !== "undefined") {
    return React.createElement(ContentBlockComponents[props.contentBlock.component.toLocaleLowerCase()],
      {
        key: props.contentBlock.id,
        contentBlock: props.contentBlock,
        showEditMode: props.showEditMode,
        setIsPanelOpen: props.setIsPanelOpen,
        renderPanelContents: props.renderPanelContents,
        closeAndUpdate: props.closeAndUpdate,
        closeAndDelete: props.closeAndDelete,
        closeAndAdd: props.closeAndAdd,
      });
  }

  // Component doesn't exist yet
  return React.createElement("div", { key: props.contentBlock.id }, `The component ${props.contentBlock.component} has not been created yet.`);
}