import { createContext, useEffect, useRef, useState } from 'react';
import i18n from '../../i18n';
import { settingsService } from '../../services/settings.service';

export const LanguageContext = createContext(null);

export const LanguageProvider = ({ children }) => {
  const isInitialMount = useRef(true);
  const [language, setLanguage] = useState('nl'); // Default language is 'en'

  useEffect(() => {
    if (isInitialMount.current) {
      settingsService.getForDomain().then(settings => {
        const languageFromSettings = settings.language.code;
        setLanguage(languageFromSettings);
      });

      isInitialMount.current = false;
    }
    else {
      // Update
      // Set display language
      i18n.changeLanguage(language.toLowerCase());
    }
  }, [language]);



  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};