import { Outlet, Route, Routes } from 'react-router-dom';
import { FormsList } from './FormsList';

export function FormsRouter() {
    return (
        <>
            <Routes>
                <Route index element={<FormsList />} />
            </Routes>
            <Outlet />
        </>
    );
}