import { Outlet, Route, Routes } from 'react-router-dom';
import { ProfileDetails } from './ProfileDetails';
import { ProfileUpdate } from './ProfileUpdate';

export function ProfileRouter() {
    return (
        <>
            <Routes>
                <Route index element={<ProfileDetails />} />
                <Route path={`update`} element={<ProfileUpdate />} />
            </Routes>
            <Outlet />
        </>
    );
}