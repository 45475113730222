import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import { IContentBlock } from '../../library/Interfaces/IContentBlock';
import { IContentBlockComponentProps } from '../../library/Interfaces/IContentBlockComponentProps';
import getQuillModules from '../../library/utils/quill-utils';
import { ImageAddZone } from '../ImageAddZone';
import { ContentBlockEditFooter } from './support/ContentBlockEditFooter';
import { ContentBlockEditToolbar } from './support/ContentBlockEditToolbar';

export function ContentBlockHeader(props: IContentBlockComponentProps) {
  const [contentBlock, setContentBlock] = useState(props.contentBlock);
  const [nameValue, setNameValue] = useState(props.contentBlock.properties.name);
  const [titleValue, setTitleValue] = useState(props.contentBlock.properties.title);
  const [textValue, setTextValue] = useState(props.contentBlock.properties.text);
  const [imagePath, setImagePath] = useState(props.contentBlock.properties.imagePath);
  const [showCallToActionChecked, setShowCallToActionChecked] = useState<boolean>(props.contentBlock.properties.showCallToAction);
  const [ctaTextValue, setCtaTextValue] = useState(props.contentBlock.properties.callToActionText);
  const [ctaUrlValue, setCtaUrlValue] = useState(props.contentBlock.properties.callToActionUrl);
  const [showTitleChecked, setShowTitleChecked] = useState<boolean>(props.contentBlock.properties.showTitle);
  const [selectedImagePosition, setSelectedImagePosition] = useState<string>(props.contentBlock.properties.imagePosition);
  const modules = getQuillModules();
  const imagePositionOptions = [{ key: 'left', text: 'left' }, { key: 'right', text: 'right' }];

  const updateContentBlock = (modifiedContentBlock: IContentBlock) => {
    contentBlock.isOnline = modifiedContentBlock.isOnline;
    setContentBlock(contentBlock);
  }

  useEffect(() => {
    setContentBlock((prevStateContentBlock) => {
      const updatedContentBlock = { ...prevStateContentBlock };
      updatedContentBlock.properties.name = nameValue;
      updatedContentBlock.properties.title = titleValue;
      updatedContentBlock.properties.text = textValue;
      updatedContentBlock.properties.imagePath = imagePath;
      updatedContentBlock.properties.showCallToAction = showCallToActionChecked;
      updatedContentBlock.properties.callToActionText = ctaTextValue;
      updatedContentBlock.properties.callToActionUrl = ctaUrlValue;
      updatedContentBlock.properties.showTitle = showTitleChecked;
      updatedContentBlock.properties.imagePosition = selectedImagePosition;
      return updatedContentBlock;
    });

    //props.renderPanelContents(editPanelContents); // Refresh, since the edit button is not invoked again once inside the component edit flow
  }, [nameValue, titleValue, textValue, imagePath, showCallToActionChecked, showTitleChecked, selectedImagePosition, ctaTextValue, ctaUrlValue]);

  const editPanelContents =
    <Container className="mt-2">
      <FloatingLabel controlId="floatingInputName" label="Name" className="mb-2">
        <Form.Control type="text" name="name" defaultValue={nameValue}
          onChange={(event) => setNameValue(event.target.value)}
          placeholder="Please fill in your name here" />
      </FloatingLabel>
      {(showTitleChecked && showTitleChecked === true) &&
        <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
          <Form.Control type="text" name="title" defaultValue={titleValue}
            onChange={(event) => setTitleValue(event.target.value)}
            placeholder="Please fill in your title here" />
        </FloatingLabel>
      }
      <Form.Group controlId="textGroup" className="mt-2">
        <Form.Label>Text</Form.Label>
        <ReactQuill theme="snow" value={textValue} modules={modules} onChange={setTextValue} />
      </Form.Group>
      <Form.Group controlId="imageGroup" className="mt-2">
        <Form.Label>Image</Form.Label>
        <ImageAddZone imagePath={props.contentBlock.properties.imagePath} setImagePath={setImagePath} />
      </Form.Group>
      <Form.Group controlId="imagePositionGroup" className="mt-4">
        <FloatingLabel controlId="lblImagePosition" label="Image position" className="mb-2">
          <Form.Control as="select" name="imagePosition" defaultValue={selectedImagePosition}
            onChange={(event) => setSelectedImagePosition(event.target.value)}
            className="form-select" required placeholder="Select a position for the image relative to the text block">
            {imagePositionOptions.map(opt => (
              <option value={opt.key}>{opt.text}</option>
            ))}
          </Form.Control>
        </FloatingLabel>
      </Form.Group>
      {showCallToActionChecked &&
        <Form.Group controlId="ctaGroup">
          <hr />
          <FloatingLabel controlId="floatingInputCtaText" label="Call to action text" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaTextValue}
              onChange={(event) => setCtaTextValue(event.target.value)}
              placeholder="Please fill in your call to action text here" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInputCtaUrl" label="Call to action url" className="mb-2">
            <Form.Control type="text" name="ctaText" defaultValue={ctaUrlValue}
              onChange={(event) => setCtaUrlValue(event.target.value)}
              placeholder="Please fill in your call to action link here" />
          </FloatingLabel>
        </Form.Group>
      }
      <Form.Group controlId="showTitleGroup" className="mt-3">
        <Form.Switch label="Show title" defaultChecked={showTitleChecked} onChange={() => setShowTitleChecked(oldValue => !oldValue)} />
      </Form.Group>
      <Form.Group controlId="showCallToActionGroup" className="mt-2">
        <Form.Switch label="Show call to action" defaultChecked={showCallToActionChecked} onChange={() => setShowCallToActionChecked(oldValue => !oldValue)} />
      </Form.Group>
      <ContentBlockEditFooter updateContentBlock={updateContentBlock} contentBlock={contentBlock} {...props} />
    </Container>;

  const imagePart = props.contentBlock.properties.imagePath ?
    <Container className="padding-large section3">
      <Image src={props.contentBlock.properties.imagePath} fluid />
    </Container>
    : null;

  const textPart = <>{(showTitleChecked && showTitleChecked === true) && titleValue &&
    <div className="h1 margintop-small">{titleValue}</div>
  }
    <div>{textValue && ReactHtmlParser(textValue as string)}</div>
    {(showCallToActionChecked && showCallToActionChecked === true) &&
      <div>
        <a className="mt-1 btn btn-dark" href={ctaUrlValue}>{ctaTextValue}</a>
      </div>
    }</>;

  return (
    <Container className="mt-2 cbHeader">
      {props.showEditMode &&
        <ContentBlockEditToolbar contentBlock={contentBlock} renderPanelContents={props.renderPanelContents} closeAndDelete={props.closeAndDelete}
          editPanelContents={editPanelContents} />}

      {props.showAddMode && editPanelContents}

      {!props.showAddMode &&
        <Container className="mt-2 bg-light" id={contentBlock.id} data-cb-sequence={contentBlock.sequence}>
          {selectedImagePosition === 'left' &&
            <Row>
              <Col lg="3" md="12" className="imagePart">{imagePart}</Col>
              <Col lg="9" md="12" className="margintop-small align-self-center textPart">{textPart}</Col>
            </Row>
          }
          {selectedImagePosition === 'right' &&
            <Row>
              <Col lg="9" md="12" className="margintop-small align-self-center textPart">{textPart}</Col>
              <Col lg="3" md="12" className="imagePart">{imagePart}</Col>
            </Row>
          }
        </Container>
      }
    </Container>
  )
}