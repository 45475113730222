import { useEffect } from 'react';
import { ISettingsJson } from '../library/Interfaces/ISettings';

export function StylesheetAppender(props) {

  useEffect(() => {
    import(`../themes/${props.settings.theme.fileNameWithoutExtension}`).then((condition) => { });

    const settingsJson: ISettingsJson = JSON.parse(props.settings.settingsJson);
    document.documentElement.style.setProperty('--themeColor1', settingsJson.branding.themeColor1);
    document.documentElement.style.setProperty('--themeColor2', settingsJson.branding.themeColor2);
    document.documentElement.style.setProperty('--themeColor3', settingsJson.branding.themeColor3);
    document.documentElement.style.setProperty('--themeColor4', settingsJson.branding.themeColor4);

  }, [props.settings]);

  return null;
}