import { Button, Stack } from 'react-bootstrap';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IContentBlockEditToolbarProps } from '../../../library/Interfaces/IContentBlockEditToolbarProps';

export function ContentBlockEditToolbar(props: IContentBlockEditToolbarProps) {
    return (
        <Stack direction="horizontal" gap={1} className="mt-2">
            <span title="Edit">
                <Button variant="light" onClick={() => { if (props.renderPanelContents) { props.renderPanelContents(props.editPanelContents) } }}><GrEdit /></Button>
            </span>
            <span title="Delete">
                <Button variant="light" onClick={() => props.closeAndDelete(props.contentBlock)}><RiDeleteBin6Line /></Button>
            </span>
        </Stack>
    )
}