import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['nl']
const supportedLngs = ['nl', 'en']

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLng,
        debug: true,
        supportedLngs: supportedLngs,
        interpolation: {
            escapeValue: false // no need for react. it escapes by default
        }
    });

export default i18n