import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Collapse, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { IPage } from '../../../library/Interfaces/IPage';
import { LanguageContext } from '../../../library/contextproviders/LanguageProvider';
import { alertService } from '../../../services/alert.service';
import { pageService } from '../../../services/page.service';
import { ContentBlocksRenderer } from '../../contentblocks/support/ContentBlocksRenderer';
import { ContentBlocksReorderer } from '../../contentblocks/support/ContentBlocksReorderer';

export interface IPageProps {
    pageTypeId: number
}

export function PageAddEdit(props: IPageProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const isAddMode = !id;
    const [contentBlocksJSON, setContentBlocksJSON] = useState<string>('');
    const isInitialMount = useRef(true);
    const [page, setPage] = useState<IPage>(null);
    const [open, setOpen] = useState(isAddMode);
    const [isReorderMode, setIsReorderMode] = useState<boolean>(false);
    const [] = useContext(LanguageContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (isAddMode) { return; }
        if (isInitialMount.current) {
            // Get page and set form fields
            pageService.getById(id).then(page => {
                setPage(page);
                setContentBlocksJSON(page.contentBlocks);
                const fields = ['title', 'slug', 'isHomePage', 'isOnline', 'useInMenu', 'useInFooter', 'pageTypeId'];
                fields.forEach(field => formik.setFieldValue(field, page[field], false));
            });
            isInitialMount.current = false;
        } else {
            // Your useEffect code here to be run on update
        }
    }, []);

    function createPage(fields, setSubmitting) {
        pageService.create(fields)
            .then(() => {
                alertService.success('Page added successfully', { keepAfterRouteChange: true });
                navigate('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error, null);
            });
    }

    function updatePage(id, fields, setSubmitting) {
        pageService.update(id, fields)
            .then(() => {
                alertService.success('Update successful', { keepAfterRouteChange: true });
                navigate('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error, null);
            });
    }

    function removeNullableKeys(obj: any): any {
        if (Array.isArray(obj)) {
            // If it's an array, process each element in the array.
            return obj.map((item: any) => removeNullableKeys(item));
        } else if (obj === null || typeof obj !== 'object') {
            // If the value is null or not an object, return it directly.
            return obj;
        }

        const result: any = {};

        for (const [key, value] of Object.entries(obj)) {
            const nonNullableValue = removeNullableKeys(value);

            if (nonNullableValue !== null) {
                // If the non-null value is not null, include it in the result.
                result[key] = nonNullableValue;
            }
            // If the non-null value is null, skip it (exclude it from the result).
        }

        return result;
    }

    function handleFormikSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            fields.pageTypeId = props.pageTypeId;
            createPage(fields, setSubmitting);
        } else {
            const contentBlocksObject = JSON.parse(contentBlocksJSON);
            const cleanedContentBlocks = removeNullableKeys(contentBlocksObject);
            fields.contentBlocks = JSON.stringify(cleanedContentBlocks);
            updatePage(id, fields, setSubmitting);
        }
    }

    const validationSchema = yup.object().shape({
        title: yup.string().required(),
        slug: yup.string().required()
    });

    const formik = useFormik({
        initialValues: { title: '', isHomePage: false, useInMenu: true, useInFooter: true, isOnline: false },
        validationSchema: validationSchema,
        onSubmit: handleFormikSubmit
    });

    return (
        <>
            {formik &&
                <Container className="mt-1 mb-3">
                    <Row className="mb-1"><h1>{isAddMode ? t('add_page') : t('edit_page')}</h1></Row>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row className="mb-1">
                            <Col md={{ span: 4, offset: 8 }} className="text-end">
                                <Form.Group className="mb-1" controlId="formButtonGroup">
                                    <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                                        {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Save
                                    </Button>
                                    {isAddMode && <Button variant="link" onClick={() => navigate('..')}>Cancel</Button>}
                                    {!isAddMode && <Button variant="link" onClick={() => navigate('..')}>Cancel</Button>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {!isAddMode &&
                            <Row className="mb-2 text-start">
                                <Col>
                                    {!isReorderMode && <Button variant="secondary" aria-expanded={open} onClick={() => { setOpen(!open) }}>{open ? 'Hide settings' : 'Show settings'}</Button>}
                                    {!isReorderMode && <Button className='ms-2' variant="secondary" onClick={() => setIsReorderMode(true)}>Change order</Button>}
                                    {isReorderMode && <Button className='ms-2' variant="secondary" onClick={() => setIsReorderMode(false)}>Confirm order</Button>}
                                </Col>
                            </Row>
                        }
                        <Collapse in={open}>
                            <Row className="mb-2">
                                <Form.Group className="mb-2" controlId="formikTitleGroup">
                                    <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-2">
                                        <Form.Control type="text" name="title" value={formik.values.title}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            placeholder="Please fill in your page title here"
                                            isInvalid={!!formik.errors.title}
                                            isValid={formik.touched.title && !formik.errors.title} />
                                    </FloatingLabel>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.title as string}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formikSlugGroup">
                                    <FloatingLabel controlId="floatingInputSlug" label="Slug" className="mb-2">
                                        <Form.Control type="text" name="slug" value={formik.values.slug}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            placeholder="Please fill in your page slug here"
                                            isInvalid={!!formik.errors.slug}
                                            isValid={formik.touched.slug && !formik.errors.slug} />
                                    </FloatingLabel>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.slug as string}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    {props.pageTypeId === 1 &&
                                        <>
                                            <Form.Switch label="Is homepage"
                                                checked={formik.values.isHomePage}
                                                disabled={page && page['isHomePage']}
                                                onChange={() => formik.setFieldValue("isHomePage", !formik.values.isHomePage)} />
                                            <Form.Switch label="Use in menu"
                                                checked={formik.values.useInMenu}
                                                onChange={() => formik.setFieldValue("useInMenu", !formik.values.useInMenu)} />
                                            <Form.Switch label="Use in footer"
                                                checked={formik.values.useInFooter}
                                                onChange={() => formik.setFieldValue("useInFooter", !formik.values.useInFooter)} />
                                        </>
                                    }
                                    <Form.Switch label="Online"
                                        disabled={page && page['isHomePage']}
                                        checked={formik.values.isHomePage ? formik.values.isHomePage : formik.values.isOnline}
                                        onChange={() => formik.setFieldValue("isOnline", !formik.values.isOnline)} />
                                </Form.Group>
                            </Row>
                        </Collapse>
                    </Form>
                </Container>
            }

            {!isAddMode && isReorderMode ? <ContentBlocksReorderer contentBlocksJSON={contentBlocksJSON} updateContentBlocksJSON={setContentBlocksJSON} /> : ''}

            {!isAddMode && !isReorderMode ? <ContentBlocksRenderer contentBlocksJSON={contentBlocksJSON} updateContentBlocksJSON={setContentBlocksJSON} showEditMode={!isAddMode} /> : ''}
        </>
    );
}