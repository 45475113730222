import React from "react";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { IContentBlockComponentProps } from "../../library/Interfaces/IContentBlockComponentProps";
import { IContentBlockProvider } from "../../library/Interfaces/IContentBlockProvider";
import { ContentBlockPhotoGallery } from "./ContentBlockPhotoGallery";

export class ContentBlockPhotoGalleryProvider implements IContentBlockProvider {
    public contentBlockPickerItem: JSX.Element = null;
    private props: any = null;

    constructor(props) {
        this.setContentBlockPickerItem();
        this.props = props;
    }

    private setContentBlockPickerItem(): void {
        this.contentBlockPickerItem = React.createElement(Button, {
            key: uuidv4(),
            variant: "light",
            onClick: (): void => {
                this.renderContentBlock()
            }
        }, "Content Block Photo Gallery");
    }

    private renderContentBlock() {
        const sequence = (this.props.contentBlock?.sequence >= 0) ? this.props.contentBlock.sequence : this.props.sequence;

        const props: IContentBlockComponentProps = {
            contentBlock: {
                id: uuidv4(),
                properties: { name: `Contentblock ${sequence}`, title: '', text: '', images: [] },
                component: 'ContentBlockPhotoGallery',
                sequence: sequence,
                isOnline: true
            },
            showAddMode: true,
            closeAndAdd: this.props.closeAndAdd,
            setIsPanelOpen: this.props.setIsPanelOpen,
            renderPanelContents: this.props.renderPanelContents,
            closeAndUpdate: this.props.closeAndUpdate,
        }
        const cb = React.createElement(ContentBlockPhotoGallery, props);
        this.props.renderPanelContents(cb);
    }
}