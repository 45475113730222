import React from "react";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { IContentBlockComponentProps } from "../../library/Interfaces/IContentBlockComponentProps";
import { IContentBlockProvider } from "../../library/Interfaces/IContentBlockProvider";
import { ContentBlockYoutube } from "./ContentBlockYoutube";

export class ContentBlockYoutubeProvider implements IContentBlockProvider {
  public contentBlockPickerItem: JSX.Element = null;
  private props: any = null;

  constructor(props) {
    this.setContentBlockPickerItem();
    this.props = props;
  }

  private setContentBlockPickerItem(): void {
    this.contentBlockPickerItem = React.createElement(Button, {
      key: uuidv4(),
      variant: "light",
      onClick: (): void => {
        this.renderContentBlock()
      }
    }, "Content Block Youtube");
  }

  private renderContentBlock() {
    const sequence = (this.props.contentBlock?.sequence >= 0) ? this.props.contentBlock.sequence : this.props.sequence;

    const props: IContentBlockComponentProps = {
      contentBlock: {
        id: uuidv4(),
        properties: { name: `Contentblock ${sequence}`, title: '', videoCode: '' },
        component: 'ContentBlockYoutube',
        sequence: sequence,
        isOnline: true
      },
      showAddMode: true,
      closeAndAdd: this.props.closeAndAdd,
      setIsPanelOpen: this.props.setIsPanelOpen,
      renderPanelContents: this.props.renderPanelContents
    }
    const cb = React.createElement(ContentBlockYoutube, props);
    this.props.renderPanelContents(cb);
  }
}