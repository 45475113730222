import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SortableTree from 'react-sortable-tree';
import { IPage } from '../../../library/Interfaces/IPage';
import { LanguageContext } from '../../../library/contextproviders/LanguageProvider';
import { getTreeFromFlatData, removeNodeAtPath } from '../../../library/utils/tree-data-utils';
import { pageService } from '../../../services/page.service';


export function PageList() {
    const location = useLocation();
    const navigate = useNavigate();
    const [pages, setPages] = useState<IPage[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [] = useContext(LanguageContext);
    const { t } = useTranslation();

    useEffect(() => {
        pageService.getAllStandardPagesForDomain().then(result => {
            setPages(result);
            buildInitialTreeItems(result);
        });
    }, []);

    const deletePage = async (id): Promise<void> => {
        setPages(pages.map(p => {
            if (p.id === id) {
                p.isDeleting = true;
            }
            return p;
        }));
        pageService._delete(id).then(() => {
            //setPages(pages => pages.filter(x => x.id !== id));
        });
    }

    const Wrapper = ({ children }: { children: React.ReactNode }) => (
        <div>
            {children}
        </div>
    );

    function buildInitialTreeItems(pageResult: IPage[]): void {
        const treeData = getTreeFromFlatData({
            flatData: pageResult.map((node) => ({ id: node.id, title: `${node.title} (${node.slug})`, name: node.title, parent: node.parentPageId })),
            getKey: node => node.id, // resolve a node's key
            getParentKey: node => node.parent, // resolve a node's parent's key
            rootKey: "null", // The value of the parent key when there is no parent (i.e., at root level)
        });
        setTreeData(treeData);
    }

    function savePageList() {
        // Save treedata as one dimensional array as menuitems
        type pageMenuItem = {
            pageId: number;
            parentPageId: number;
            sequence: number;
        };

        const pageMenuOrder: pageMenuItem[] = [];

        const pushMenuItem = (parentPageId: number, menuItem, counter: number) => {
            pageMenuOrder.push({
                pageId: menuItem.id,
                parentPageId: parentPageId,
                sequence: counter
            });

            if (menuItem.children) {
                for (let index = 0; index < menuItem.children.length; index++) {
                    const menuChildItem = menuItem.children[index];
                    pushMenuItem(menuItem.id, menuChildItem, index);
                }
            }
        }

        // Loop recursively and push children as menuitems
        for (let index = 0; index < treeData.length; index++) {
            const menuItem = treeData[index];
            pushMenuItem(menuItem.parent, menuItem, index);
        }

        // In backend save parentpageid and sequence accordingly
        pageService.savePageMenuOrder({ treeData: pageMenuOrder });
    }

    const removePageNode = (rowInfo) => {
        let { node, treeIndex, path } = rowInfo;

        if (!window.confirm(`Are you sure you want to delete page '${node.name}' and all child pages?`)) {
            return;
        }

        const newTreeData = removeNodeAtPath({
            treeData: treeData,
            path: path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: true
        });

        setTreeData(newTreeData);

        deletePage(node.id);
    };

    return (
        <Container className="mt-1 mb-3">
            <Row className="mb-1 h1">{t('pages')}</Row>
            <Row className="mb-1">
                <Col md={{ span: 4, offset: 8 }} className="text-end">
                    <Form.Group className="mb-1" controlId="formButtonGroup">
                        <Link to={`${location.pathname}/add`}>
                            <Button variant="primary">{t('add_page')}</Button>
                        </Link>
                        <Button variant="secondary" onClick={savePageList} className='m-2'>{t('save')}</Button>
                    </Form.Group>
                </Col>
            </Row>

            <Container>
                <div>Homepage</div>

                <div>Pages in menu</div>

                {treeData &&
                    <Wrapper>
                        <SortableTree isVirtualized={false}
                            maxDepth={3}
                            treeData={treeData}
                            onChange={treeData => { console.log(treeData); setTreeData(treeData) }}
                            generateNodeProps={(rowInfo) => ({
                                buttons: [
                                    <button className="btn btn-light" title='Edit' onClick={() => { navigate(`./edit/${rowInfo.node.id}`); }}><GrEdit /></button>,
                                    <button className="btn btn-light" title='Delete' onClick={(event) => removePageNode(rowInfo)}><RiDeleteBin6Line /></button>,
                                ],
                            })}
                        />
                    </Wrapper>
                }

                <div>Pages not in menu</div>
            </Container>
        </Container>
    );
}