import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Modal, Row, Stack, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../library/contextproviders/LanguageProvider';
import { IDropDownOption } from '../../../library/Interfaces/IDropDownOption';
import { ILanguage } from '../../../library/Interfaces/ILanguage';
import { ISettings, ISettingsJson } from '../../../library/Interfaces/ISettings';
import { ITheme } from '../../../library/Interfaces/ITheme';
import { alertService } from '../../../services/alert.service';
import { languageService } from '../../../services/language.service';
import { settingsService } from '../../../services/settings.service';
import { themeService } from '../../../services/theme.service';
import { ImageAddZone } from '../../ImageAddZone';

export function SettingsEdit() {
    const isInitialMount = useRef(true);
    const [availableLanguages, setAvailableLanguages] = useState<any>(null);
    const [languageDropDownOptions, setLanguageDropDownOptions] = useState<IDropDownOption[]>(null);
    const [availableThemes, setAvailableThemes] = useState<any>(null);
    const [themeDropDownOptions, setThemeDropDownOptions] = useState<IDropDownOption[]>(null);
    const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
    const [selectedTheme, setSelectedTheme] = useState<any>(null);
    const [settingsRetrieved, setSettingsRetrieved] = useState<boolean>(false);
    const [settingsId, setSettingsId] = useState<number>(null);
    const [settings, setSettings] = useState<ISettingsJson>(null);
    const [isLogoEditModeDark, setIsLogoEditModeDark] = useState<boolean>(false);
    const [logoDarkPath, setLogoDarkPath] = useState<string>(null);
    const [logoLightPath, setLogoLightPath] = useState<string>(null);
    const [settingsNeedsUpdate, setNeedsUpdate] = useState<boolean>(false);
    const [, setLanguage] = useContext(LanguageContext);
    const { t, i18n } = useTranslation();
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    useEffect(() => {
        if (isInitialMount.current) {
            // Get page and set form fields
            // TODO: use react ref to only load once? Also use redux to cache domain settings for browser session? 
            settingsService.getForDomain().then((settings: ISettings) => {
                // TODO: what if not set (initial)
                setSelectedLanguage(settings.language);
                setSelectedTheme(settings.theme);
                setSettingsId(settings.id);

                // Parse Json
                if (settings.settingsJson) {
                    const settingsJson: ISettingsJson = JSON.parse(settings.settingsJson);
                    setSettings(settingsJson);

                    setLogoDarkPath(settingsJson.branding.logoDarkUrl);
                    setLogoLightPath(settingsJson.branding.logoLightUrl);
                }
                else {
                    // TODO: Initiate
                }

                setSettingsRetrieved(true);
            });

            // Get all available languages
            languageService.getAll().then(languages => {
                setAvailableLanguages(languages);
                const languageDropDownOptions = languages.map((language: ILanguage) => {
                    return { key: language.code, text: language.title }
                });
                setLanguageDropDownOptions(languageDropDownOptions);
            });

            // Get all available themes
            themeService.getAll().then(themes => {
                setAvailableThemes(themes);
                const themeDropDownOptions = themes.map((theme: ITheme) => {
                    return { key: theme.fileNameWithoutExtension, text: theme.fileNameWithoutExtension }
                });
                setThemeDropDownOptions(themeDropDownOptions);
            });

            isInitialMount.current = false;
        } else {
            // Your useEffect code here to be run on update
        }
    }, []);

    const updateSettings = async () => {
        try {
            const result = await settingsService.update(settingsId, {
                Theme: selectedTheme, Language: selectedLanguage, SettingsJson: JSON.stringify(settings)
            });

            // setLogoDarkImagePath(result.logoDarkUrl);
            // setLogoLightImagePath(result.logoLightUrl);
            setLanguage(selectedLanguage.code);
            setNeedsUpdate(false);

            //alertService.success('Update successful', { keepAfterRouteChange: true });
        }
        catch (error) {
            alertService.error(error, null);
        }
    }

    const changeTheme = (event) => {
        const theme = availableThemes.find(x => x.fileNameWithoutExtension === event.target.value);
        setSelectedTheme(theme);
        setNeedsUpdate(true);
    }

    const changeLanguage = (event: any) => {
        const language = availableLanguages.find(x => x.code === event.target.value);
        setSelectedLanguage(language);
        setNeedsUpdate(true);
    }

    const setLogoDarkImagePath = (imagePath: string) => {
        setLogoDarkPath(imagePath);
        setSettingsByCategory('branding', 'logoDarkUrl', imagePath);
        setNeedsUpdate(true);
    }

    const setLogoLightImagePath = (imagePath: string) => {
        setLogoLightPath(imagePath);
        setSettingsByCategory('branding', 'logoLightUrl', imagePath);
        setNeedsUpdate(true);
    }

    // Update the state
    function setSettingsByCategory(settingCategory: string, settingName: string, value: any) {
        setSettings((prevObject) => ({
            ...prevObject,
            [settingCategory]: {
                ...prevObject[settingCategory],
                [settingName]: value,
            },
        }));
    }

    // Function to update settings dynamically
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const settingCategory: string = name.split('.')[0];
        const settingName: string = name.split('.')[1];

        if (event.target.type === 'checkbox') {
            setSettingsByCategory(settingCategory, settingName, event.target.checked);
        } else {
            setSettingsByCategory(settingCategory, settingName, value);
        }

        setNeedsUpdate(true);
    };

    const editLogoPanelContents = <Form.Group>
        {isLogoEditModeDark ?
            <ImageAddZone imagePath={logoDarkPath} setImagePath={setLogoDarkImagePath} /> :
            <ImageAddZone imagePath={logoLightPath} setImagePath={setLogoLightImagePath} />
        }
        <Stack direction="horizontal" gap={2} className="mt-2 justify-content-end">
            <Button className='mt-2' variant="primary" onClick={() => setIsPanelOpen(false)}>Save changes</Button>
            <Button className='mt-2' variant="light" onClick={() => setIsPanelOpen(false)}>Close</Button>
        </Stack>
    </Form.Group>;

    return (
        <>
            {settingsRetrieved && availableLanguages && availableThemes &&
                <Container className="mt-1 mb-3">
                    <Row className="mb-1 h1">{t('edit_settings')}</Row>
                    <Row className="mb-1">
                        <Col md={{ span: 4, offset: 8 }} className="text-end">
                            <Form.Group className="mb-1" controlId="formButtonGroup">
                                <Button onClick={updateSettings} variant="primary" disabled={!settingsNeedsUpdate}>{t('save')}</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Form>
                                <Tabs defaultActiveKey="general" id="settingsTabs" className="mt-2">
                                    <Tab eventKey="general" key="generalTab" title="General" className='mt-2 p-2'>
                                        <FloatingLabel controlId="lblLanguage" key="lblLanguage" label="Language" className="mb-2">
                                            <Form.Control as="select" name="language" defaultValue={selectedLanguage ? selectedLanguage.code : null} onChange={changeLanguage}
                                                className="form-select" required placeholder="Select a language">
                                                {languageDropDownOptions.map(opt => (
                                                    <option value={opt.key} key={`option-${opt.key}`}>{opt.text}</option>
                                                ))}
                                            </Form.Control>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblSiteTitle" label="Site title" className="mb-2">
                                            <Form.Control type="text" name="general.siteTitle" defaultValue={settings.general.siteTitle} onChange={handleInputChange} placeholder="Please fill in your site title here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblSiteDescription" label="Site description" className="mb-2">
                                            <Form.Control as="textarea" rows={2} className="h-100" name="general.siteDescription" defaultValue={settings.general.siteDescription} onChange={handleInputChange} placeholder="Please fill in your site description here (used for search engines)" />
                                        </FloatingLabel>
                                    </Tab>
                                    <Tab eventKey="branding" key="brandingTab" title="Branding" className='mt-2 p-2'>
                                        <FloatingLabel controlId="lblTheme" label="Theme" className="mb-2">
                                            <Form.Control as="select" name="theme" defaultValue={selectedTheme.fileNameWithoutExtension} onChange={changeTheme} className="form-select" required placeholder="Select a theme">
                                                {themeDropDownOptions.map(opt => (
                                                    <option value={opt.key} key={`option-${opt.key}`}>{opt.text}</option>
                                                ))}
                                            </Form.Control>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblThemeColor1" label="Theme color 1" className="mb-2">
                                            <Form.Control type="text" name="branding.themeColor1" defaultValue={settings.branding.themeColor1} onChange={handleInputChange} placeholder="Please fill in your hexadecimal code for theme color 1 here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblThemeColor2" label="Theme color 2" className="mb-2">
                                            <Form.Control type="text" name="branding.themeColor2" defaultValue={settings.branding.themeColor2} onChange={handleInputChange} placeholder="Please fill in your hexadecimal code for theme color 2 here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblThemeColor2" label="Theme color 3" className="mb-2">
                                            <Form.Control type="text" name="branding.themeColor3" defaultValue={settings.branding.themeColor3} onChange={handleInputChange} placeholder="Please fill in your hexadecimal code for theme color 3 here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblThemeColor4" label="Theme color 4" className="mb-2">
                                            <Form.Control type="text" name="branding.themeColor4" defaultValue={settings.branding.themeColor4} onChange={handleInputChange} placeholder="Please fill in your hexadecimal code for theme color 4 here" />
                                        </FloatingLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="text" placeholder="Please provide a dark version of your logo" aria-label="Logo dark"
                                                defaultValue={logoDarkPath?.startsWith('data:image') ? 'Dark logo ready to be saved' : logoDarkPath} />
                                            <Button variant="outline-secondary" id="btnLogoDark" onClick={() => { setIsLogoEditModeDark(true); setIsPanelOpen(true); }}>
                                                {logoDarkPath ? 'Edit dark logo' : 'Add dark logo'}
                                            </Button>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="text" placeholder="Please provide a light version of your logo" aria-label="Logo light"
                                                defaultValue={logoLightPath?.startsWith('data:image') ? 'Dark logo ready to be saved' : logoLightPath} />
                                            <Button variant="outline-secondary" id="btnLogoLight" onClick={() => { setIsLogoEditModeDark(false); setIsPanelOpen(true); }}>
                                                {logoLightPath ? 'Edit light logo' : 'Add light logo'}
                                            </Button>
                                        </InputGroup>

                                        {isPanelOpen &&
                                            <div id="ContentBlockEditPanel">
                                                <Modal className='right fade' size='lg' show={isPanelOpen} onHide={() => setIsPanelOpen(false)}>
                                                    <Modal.Header closeButton><Modal.Title>Logo edit panel</Modal.Title></Modal.Header>
                                                    <Modal.Body>{editLogoPanelContents}</Modal.Body>
                                                </Modal>
                                            </div >
                                        }
                                    </Tab>
                                    <Tab eventKey="footer" key="footerTab" title="Footer" className='mt-2 p-2'>
                                        <FloatingLabel controlId="lblInstagramUrl" label="InstagramUrl" className="mb-2">
                                            <Form.Control type="text" name="footer.instagramUrl" defaultValue={settings.footer.instagramUrl} onChange={handleInputChange} placeholder="Please fill in your instagram link here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblFacebookUrl" label="FacebookUrl" className="mb-2">
                                            <Form.Control type="text" name="footer.facebookUrl" defaultValue={settings.footer.facebookUrl} onChange={handleInputChange} placeholder="Please fill in your facebook link here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblLinkedInUrl" label="LinkedInUrl" className="mb-2">
                                            <Form.Control type="text" name="footer.linkedInUrl" defaultValue={settings.footer.linkedInUrl} onChange={handleInputChange} placeholder="Please fill in your linked in link here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblTermsAndConditionsUrl" label="TermsAndConditionsUrl" className="mb-2">
                                            <Form.Control type="text" name="footer.termsAndConditionsUrl" defaultValue={settings.footer.termsAndConditionsUrl} onChange={handleInputChange} placeholder="Please fill in the link to your terms and conditions page here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblPrivacyStatementUrl" label="PrivacyStatementUrl" className="mb-2">
                                            <Form.Control type="text" name="footer.privacyStatementUrl" defaultValue={settings.footer.privacyStatementUrl} onChange={handleInputChange} placeholder="Please fill in the link to your privacy statement page here" />
                                        </FloatingLabel>
                                    </Tab>
                                    <Tab eventKey="forms" key="formsTab" title="Formulieren" className='mt-2 p-2'>
                                        <FloatingLabel controlId="lblSiteKeyRecaptcha" label="Site key voor recaptcha" className="mb-2">
                                            <Form.Control type="text" name="forms.siteKeyRecaptcha" defaultValue={settings.forms.siteKeyRecaptcha} onChange={handleInputChange} placeholder="Please fill in your site key for recaptcha here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblContactFormSendText" label="Text after contact form send" className="mb-2">
                                            <Form.Control as="textarea" rows={2} className="h-100" name="forms.contactFormSendText" defaultValue={settings.forms.contactFormSendText} onChange={handleInputChange} placeholder="Please fill in your text here to display after a contact form has been send" />
                                        </FloatingLabel>
                                    </Tab>
                                    <Tab eventKey="portfolio" key="portfolioTab" title="Portfolio" className='mt-2 p-2'>
                                        <Form.Switch label="Online"
                                            checked={settings.portfolio.isOnline} name='portfolio.isOnline' onChange={handleInputChange} />
                                        <FloatingLabel controlId="lblPortfolioDisplayName" label="Naam voor portfolio" className="mb-2">
                                            <Form.Control type="text" name="portfolio.displayName" defaultValue={settings.portfolio?.displayName ?? ''} onChange={handleInputChange} placeholder="Please fill in your portfolio display name here" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="lblPortfolioSlug" label="Slug voor portfolio" className="mb-2">
                                            <Form.Control type="text" name="portfolio.slug" defaultValue={settings.portfolio?.slug ?? ''} onChange={handleInputChange} placeholder="Please fill in your portfolio slug here" />
                                        </FloatingLabel>
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Col>
                        <Col sm={6}></Col>
                    </Row>
                </Container>
            }
        </>
    );
}