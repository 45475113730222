import { Outlet, Route, Routes } from 'react-router-dom';
import { PageAddEdit } from '../pages/PageAddEdit';
import { PortfolioList } from './PortfolioList';

export function PortfolioRouter() {
    return (
        <>
            <Routes>
                <Route index element={<PortfolioList />} />
                <Route path={`add`} element={<PageAddEdit pageTypeId={3} />} />
                <Route path={`edit/:id`} element={<PageAddEdit pageTypeId={3} />} />
            </Routes>
            <Outlet />
        </>
    );
}