import cuid from 'cuid';
import { useCallback, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Resizer from "react-image-file-resizer";
import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const DropZoneContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;

type Image = { id: string, src: string | ArrayBuffer | null };

export function ImageAddZone(props) {
    const [image, setImage] = useState<Image | null>({ id: '', src: props.imagePath as string });

    const reader = new FileReader();

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                new Blob([file as ArrayBuffer]),
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);

        const file = acceptedFiles[0];
        reader.onload = async function (e) {
            const image = await resizeFile(reader.result);
            setImage({ id: cuid(), src: image as string });
            props.setImagePath(image);
        };
        //reader.readAsDataURL(file);
        reader.readAsArrayBuffer(file);
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        maxFiles: 1,
        noDragEventsBubbling: true, // Ñecessary when using react dropzone inside a modal window!
        accept: 'image/jpeg,image/png'
    });

    const files = acceptedFiles.map((file: FileWithPath) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <Container className="mt-2">
            <DropZoneContainer {...getRootProps({ className: 'dropzone', isFocused, isDragAccept, isDragReject })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </DropZoneContainer>
            {image && image.src &&
                <Container className="mt-2" fluid>
                    <Row><ul>{files}</ul></Row>
                    <Row>
                        <Col className="text-center">
                            <Image fluid
                                src={image.src as string}
                                alt={`img - ${image.id}`}
                                width="50%"
                            />
                        </Col>
                    </Row>
                </Container>
            }
        </Container>
    );
}