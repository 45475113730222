import { fetchWrapper } from '../library/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_API_URL}/forms`;

export const formService = {
    getForDomain,
    send,
    validateCaptcha,
    _delete
};

function getForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetForDomain`);
}

function send(params) {
    return fetchWrapper.post(baseUrl, params);
}

function validateCaptcha(token) {
    return fetchWrapper.post(`${baseUrl}/validate-captcha`, { token });
}

function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            return x;
        });
}