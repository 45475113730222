import { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { formService } from '../../../services/form.service';

export function FormsList() {
    const isInitialMount = useRef(true);
    const [formItems, setFormItems] = useState<any>(null);
    const [selectedFormItem, setSelectedFormItem] = useState<any>(null);
    const [selectedFormJson, setSelectedFormJson] = useState<any>(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);


    useEffect(() => {
        if (isInitialMount.current) {
            // Get form items for domain
            formService.getForDomain().then(forms => {
                setFormItems(forms);
            });

            isInitialMount.current = false;
        } else {
            // Your useEffect code here to be run on update
        }
    }, []);

    const deleteFormItem = (formItemId) => {
        if (!window.confirm(`Are you sure you want to delete this form?`)) {
            return;
        }

        formService._delete(formItemId).then(() => {
            // Refresh form items
            setFormItems(formItems => formItems.filter(x => x.id !== formItemId));
        });
    };

    return (
        <>
            <Container className="mt-1 mb-3">
                <Row className="mb-1 h1">Formulieren</Row>
                <Row className='fw-bold'>
                    <Col sm={3}>Date</Col>
                    <Col sm={9}>Name</Col>
                </Row>
                {formItems && formItems.length > 0 && formItems.map((formItem, index) => {
                    const formJson = JSON.parse(formItem.formJson);

                    return (
                        <Row onClick={() => { setSelectedFormItem(formItem); setSelectedFormJson(formJson); setIsPanelOpen(true); }}>
                            <Col sm={3}>{formItem.created}</Col>
                            <Col sm={7}>{formJson.name}</Col>
                            <Col sm={2}>
                                <button className="btn btn-light" title='Delete'
                                    onClick={(event) => { event.stopPropagation(); deleteFormItem(formItem.id) }}>
                                    <RiDeleteBin6Line />
                                </button>
                            </Col>
                        </Row>
                    );
                })}
                {formItems && formItems.length === 0 &&
                    <Row>
                        <Col sm={12}>There are no forms yet</Col>
                    </Row>
                }
            </Container>
            {isPanelOpen && selectedFormItem &&
                <div id="FormDetailsPanel">
                    <Modal className='right fade' size='lg' show={isPanelOpen} onHide={() => setIsPanelOpen(false)}>
                        <Modal.Header closeButton><Modal.Title>Formulier details</Modal.Title></Modal.Header>
                        <Modal.Body>
                            <Container className="mt-1 mb-3">
                                <Form>
                                    <Form.Group className="mb-3" controlId="detailForm.Name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" value={selectedFormJson.name} readOnly />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="detailForm.Email">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" value={selectedFormJson.email} readOnly />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="detailForm.Phone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="text" value={selectedFormJson.phone} readOnly />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="detailForm.Message">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={8} value={selectedFormJson.message} readOnly />
                                    </Form.Group>
                                </Form>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
}