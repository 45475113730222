import { Link, useLocation } from 'react-router-dom';
import { accountService } from '../../../services/account.service';

export function ProfileDetails() {
    const location = useLocation();
    const user: any = accountService.userValue;

    return (
        <div>
            <h1>My Profile</h1>
            <p>
                <strong>Name: </strong> {user.title} {user.firstName} {user.lastName}<br />
                <strong>Email: </strong> {user.email}
            </p>
            <p><Link to={`${location.pathname}/update`}>Update Profile</Link></p>
        </div>
    );
}