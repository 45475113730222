import { fetchWrapper } from '../library/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_API_URL}/pages`;

export const pageService = {
    getAllPagesForDomain,
    getAllStandardPagesForDomain,
    getAllPortfolioPagesForDomain,
    getAllFooterPagesForDomain,
    getMenuItemsForDomain,
    getHomepageForDomain,
    getBySlugForDomain,
    getById,
    create,
    update,
    savePageMenuOrder,
    _delete,
};

function getAllPagesForDomain() {
    return fetchWrapper.get(`${baseUrl}/getAllPagesForDomain`);
}

function getAllStandardPagesForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetAllStandardPagesForDomain`);
}

function getAllPortfolioPagesForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetAllPortfolioPagesForDomain`);
}

function getAllFooterPagesForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetAllFooterPagesForDomain`);
}

function getMenuItemsForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetMenuItemsForDomain`);
}

function getBySlugForDomain(slug: string) {
    return fetchWrapper.get(`${baseUrl}/GetBySlugForDomain/${slug}`);
}

function getHomepageForDomain() {
    return fetchWrapper.get(`${baseUrl}/GetHomepageForDomain`);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            return x;
        });
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
        .then(page => {
            // // update stored page if the logged in user updated their own record
            // if (page.id === userSubject.value.id) {
            //     // publish updated user to subscribers
            //     user = { ...userSubject.value, ...user };
            //     userSubject.next(user);
            // }
            return page;
        });
}

function savePageMenuOrder(treeData) {
    return fetchWrapper.put(`${baseUrl}/PageMenuOrder`, treeData)
        .then(page => {
            return page;
        });
}